import React from 'react';
import { Box } from '@mui/material';
import { AppToastMessage } from '../../../hooks/core/AppToastContextProvider';
import AppToastItem from './AppToastItem';

interface Props {
    messages: AppToastMessage[]
}

const AppToast: React.FC<Props> = (props) => {

    const { messages } = props;

    return (
        <Box sx={{ position: "absolute", right: "0", top: "0", padding: "1rem", overflow: "hidden", zIndex: 99999999 }}>
            {messages.map((message) => {
                return (
                <AppToastItem key={message.id}  message={message} />
                );
            })}

        </Box>
    );
}

export default AppToast;