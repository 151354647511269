import { Paper } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { LayoutState } from '../../state/reducers/layout.reducer';

interface AppCardProps {
    children?: React.ReactNode | React.ReactNode[];
}

const AppCard: React.FC<AppCardProps> = (props) => {

    const layout = useSelector((state: any) => state.layout as LayoutState);

    return (
        
        <Paper elevation={0} sx={{ 
          p: layout.windowSize.isMobile ? 2 : 4,
          mt: layout.windowSize.isMobile ? 1 : 2,
          borderRadius: 4, 
          boxShadow: "0px 0px  5px #ccc" 
        }}>
            {props.children}
        </Paper>
    );
}

export default AppCard;