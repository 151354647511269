import { Help } from "@mui/icons-material";
import { Box } from "@mui/material";
import { FC, useState } from "react";


interface AppTooltipProps {
    children: JSX.Element | string | null | undefined;
    position?: "top" | "bottom" | "left" | "right";
}

const AppTooltip: FC<AppTooltipProps> = ({children, position}) => {
  
  const [isHovered, setIsHovered] = useState(false);
  
  if(!children)
    return <></>

  let positionStyle = {};

  switch(position){
    case "top":
      positionStyle = {bottom:"32px", transform:"translateX(-50%)"}
      break;
    case "bottom":
      positionStyle = {top:"24px", left:"0", transform:"translateX(-50%)"}
      break;
    case "left":
       positionStyle = {
          bottom:"50%", 
          left:"-4px", 
          transform:"translate(-100%, 50%)",
        }
      break;
    case "right":
        positionStyle = {
          bottom:"50%", 
          right:"-4px", 
          transform:"translate(100%, 50%)",
        }
      break;
    default:  
      positionStyle = {bottom:"24px", left:"0", transform:"translateX(-40%)"}
      break;
  }
  
    return (
      <Box sx={{ position: "relative", cursor:"pointer"}}>
        <Box sx={{pl:1}} onMouseOver={()=>setIsHovered(true)} onMouseLeave={()=>setIsHovered(false)} >
          <Help fontSize="small" sx={{color:"#777"}} />
          {isHovered && 
          <Box sx={{
            ...positionStyle,
            position:"absolute", 
            zIndex:4, 
            backgroundColor:"white", 
            width: "content-fit",
            minWidth: "200px",
            borderRadius:2, 
            p:1, 
            boxShadow:"0 0 4px #ccc",
            border:"1px solid #ccc"
          }}>
            {children}
          </Box>}
        </Box>
        

      </Box>
        
    )
}

export default AppTooltip;