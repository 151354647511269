import { Box, SxProps, Theme, Typography } from "@mui/material";
import { FC } from "react"
import AppHamburgerIcon from "../../../components/icons/AppHamburgerIcon";
import { Link } from "react-router-dom";

export interface CardInfoProps {
    title: string,
    sx?: SxProps<Theme>;
    icon?: React.ReactNode;
    link?: string
}

const CardInfo: FC<CardInfoProps> = (props) => {

    const { title, icon, sx, link } = props;
    return (
        <Link to={link || ""}>
        <Box sx={{
            p: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
            width: "100%",
            backgroundColor: "#FFF",
            borderRadius: "12px",
            border: "1px solid #E0E0E0",
            cursor: "pointer",
            ...sx
        }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                { icon && icon}
                <Typography variant="body1" component="p" sx={{ fontWeight: "bold" }}>
                    {title}
                </Typography>
            </Box>

            <AppHamburgerIcon />
        </Box>
        </Link>
    );
}

export default CardInfo;