import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { AnamnesisRefData, AnamnesisRefDataStore, ApiMetabolicScreeningItem } from './types.d';

export const useAnamnesisRefDataStore = create<AnamnesisRefDataStore>()(
    persist(
        (set) => ({
            data: {
                metabolicScreeningItemsRef: [] as ApiMetabolicScreeningItem[]
            },
            setData: (propName: keyof AnamnesisRefData, data: any) => {
                set((state) => {
                    return {
                        ...state,
                        data: {
                            ...state.data,
                            [propName]: data
                        }
                    }
                });
            }
        }),
        {
            name: 'app-nutrifit-anamnesis-ref-data-store',
            getStorage: () => localStorage,
        }
    )
);