import { FC, useCallback, useEffect, useState } from "react";
import AssessmentIcon from '@mui/icons-material/Assessment';
import AppFormTitleDivider from "../../components/AppFormTitleDivider";
import { Alert, Backdrop, Box, Button, Chip, Fade, Grid, Modal, Typography } from "@mui/material";
import { useAnthropometricStore } from "./store/AnthropometricStore";
import { BodyCompositionType } from "./enums/BodyCompositionType";
import { ResultSectionProps } from "./interfaces/SectionProps";
import { Calculate } from "@mui/icons-material";
import AppTable, { AppTableColumn, AppTableRowData } from "../../../../components/layout/AppTable/AppTable";
import { ChartsReferenceLine, LineChart, LinePlot, LineSeriesType, MarkPlot } from "@mui/x-charts";
import AnthropometricService from "../../../../services/anthropometric.services";
import { usePatientStore } from "../../Stores/PatientStore/PatientStore";
import { MakeOptional } from "@mui/x-date-pickers/internals";
import { getFormattedNumber, round } from "../../../../utils/numberUtils";
import { AnthropometricAgeChart } from "../../../../domain/interfaces/PatientIterations/AnthropometricAgeChart";
import { PatientType } from "./enums/PatientType";
import AppLoading from "../../../../components/utils/AppLoading";
import { ChildResultType } from "./enums/ChildResultType";
import { getFormattedDate } from "../../../../utils/dateUtils";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    maxWidth: "820px",
    bgcolor: 'background.paper',
    border: '1px solid #ccc',
    boxShadow: 12,
    p: 2,
    m: 2,
    display: "flex",
    overflow: "auto",
    alignItems: "start",
    justifyContent: "space-between",
    flexFlow: "column",
    flexDirection: "column"
};

interface AnthropometricChart {
    p03Serie: number,
    p15Serie: number,
    p50Serie: number,
    p85Serie: number,
    p97Serie: number,
    ageMonth: number,
    pacient?: number
}

interface AgeMonthRange {
    minAgeMonth: number,
    maxAgeMonth: number
}

interface ChartTitle {
    title: string,
    suffix: string,
    type: string
}

const adultColumnsTable: AppTableColumn[] = [
    { prop: 'description', label: 'Descrição', maxWidth: "300px", align: "center" },
    { prop: 'now', label: 'Atual', maxWidth: "300px", align: "center" },
    { prop: 'recomendation', label: 'Recomendação', maxWidth: "300px", align: "center" },
    { prop: 'situation', label: 'Situação', maxWidth: "300px", align: "center" }
];

const childColumnsTable: AppTableColumn[] = [
    { prop: 'description', label: 'Descrição', maxWidth: "300px", align: "center" },
    { prop: 'now', label: 'Atual', maxWidth: "300px", align: "center" },
    { prop: 'recomendation', label: 'Recomendação', maxWidth: "300px", align: "center" },
    { prop: 'situation', label: 'Situação', maxWidth: "300px", align: "center" },
    { prop: 'chart', label: 'Gráfico', maxWidth: "60px", align: "center" },
];

const anthropometricChartSeries: MakeOptional<LineSeriesType, "type">[] = [
    {
        showMark: false,
        color: "#fd7e14",
        label: "Percentil 03",
        dataKey: "p03Serie"
    },
    {
        showMark: false,
        color: "#ffff00",
        label: "Percentil 15",
        dataKey: "p15Serie"
    },
    {
        showMark: false,
        color: "#77d600",
        label: "Percentil 50",
        dataKey: "p50Serie"
    },
    {
        showMark: false,
        color: "#ffff00",
        label: "Percentil 85",
        dataKey: "p85Serie"
    },
    {
        showMark: false,
        color: "#fd7e14",
        label: "Percentil 97",
        dataKey: "p97Serie"
    },
    {
        showMark: true,
        color: "#428bca",
        label: "Paciente",
        dataKey: "pacient",

    },
];

interface ResultTitles {
    [key: string]: ChartTitle
}

const resultsTitles: ResultTitles = {
    Weight: {
        title: "Peso X Idade",
        suffix: " Kg",
        type: "Peso"
    } as ChartTitle,
    Height: {
        title: "Altura(cm) X Idade",
        suffix: " cm",
        type: "Altura(cm)"
    } as ChartTitle,
    BMI: {
        title: "IMC X Idade",
        suffix: "",
        type: "IMC"
    } as ChartTitle
}

const ResultsSection: FC<ResultSectionProps> = ({ tableResult, isAllMeasures, isBmi, showTableResult }) => {
    const store = useAnthropometricStore(state => state.data);
    const data = store;

    const [yAxis, setYAxis] = useState<number[]>([1, 2, 3]);
    const [yAxisPatientValue, setYAxisPatientValue] = useState<number>(1);
    const [isLoading, setIsLoading] = useState(false);
    const [ageMonthRange, setAgeMonthRange] = useState<AgeMonthRange>({} as AgeMonthRange);
    const [isShowAgeMessage, setIsShowAgeMessage] = useState<boolean>();
    const [dataset, setDataset] = useState<any>([]);
    const [chartTitle, setChartTitle] = useState({} as ChartTitle);

    const { patient } = usePatientStore();

    const [chartOpen, setChartOpen] = useState(false);

    const handleClose = () => setChartOpen(false);

    const getAdultResults = useCallback((): AppTableRowData[] => {
        const results = tableResult.map(result => {
            const situation = result.massRecomendation.situationSeverity
                ? <Chip label={result.massRecomendation.description} className={result.massRecomendation.situationSeverity} size="small" />
                : result.massRecomendation.description;

            return {
                description: { value: result.description },
                now: { value: result.valueFormatted },
                recomendation: { value: result.massRecomendation.recomendation },
                situation: { value: situation }
            } as AppTableRowData;
        });

        return results;
    }, [tableResult]);


    const getAgeFormatted = useCallback((monthAge: number) => {
        if (monthAge === 0) return "0";

        if (monthAge % 12 === 0) {
            let yearsAge = monthAge / 12;
            return yearsAge === 1 ? "1 ano" : `${yearsAge} anos`;
        }

        return monthAge === 1 ? "1 mês" : `${monthAge} meses`;
    }, []);

    const setChartYAxis = useCallback((maxValue: number) => {
        let yAxis: number[] = [];
        for (let i = 0; i <= maxValue; i += 10) {
            yAxis.push(i);
        }
        setYAxis(yAxis);
    }, []);

    const getAnthropometricDatasetChart = useCallback((
        anthropometricAgeChart: AnthropometricAgeChart[],
        patientValue: number) => {
        const firstResult = anthropometricAgeChart[0];
        const lastResult = anthropometricAgeChart[anthropometricAgeChart.length - 1];

        setChartYAxis(lastResult.p97);

        let ageMonthRange: AgeMonthRange = {
            minAgeMonth: firstResult.ageMonth,
            maxAgeMonth: lastResult.ageMonth
        }
        setAgeMonthRange(ageMonthRange);

        const ageMonthPatient = data.ageInMonths;
        let anthropometricChart: AnthropometricChart[] = [];

        anthropometricAgeChart.forEach(i => {
            let item = {
                p03Serie: round(i.p03),
                p15Serie: round(i.p15),
                p50Serie: round(i.p50),
                p85Serie: round(i.p85),
                p97Serie: round(i.p97),
                ageMonth: i.ageMonth,
                pacient: undefined
            } as AnthropometricChart

            if (ageMonthPatient === i.ageMonth) {
                item.pacient = patientValue;
            }
            anthropometricChart.push(item);
        });

        setDataset(anthropometricChart);
    }, [setAgeMonthRange, setDataset, data.ageInMonths, setChartYAxis]);


    const handleChartOpen = useCallback(async (childResultType: ChildResultType | undefined, value: number) => {
        if (patient.id === undefined) return;
        if (childResultType === undefined) return;
        setYAxisPatientValue(value);

        let chart: AnthropometricAgeChart[];
        setIsLoading(true);
        setChartTitle(resultsTitles[ChildResultType[childResultType]]);

        switch (childResultType) {
            case ChildResultType.Weight:
                chart = await AnthropometricService.getAgeWeightChartAnthropometrics(patient.id);
                break;
            case ChildResultType.Height:
                chart = await AnthropometricService.getAgeHeightChartAnthropometrics(patient.id);
                break;
            case ChildResultType.BMI:
                chart = await AnthropometricService.getAgeBMIChartAnthropometrics(patient.id);
                break;
            default:
                chart = []
        };

        getAnthropometricDatasetChart(chart, value);
        setChartOpen(true);
        setIsLoading(false);
    }, [setChartOpen, getAnthropometricDatasetChart, setIsLoading, patient.id, setYAxisPatientValue]);

    const getChildResults = useCallback((): AppTableRowData[] => {
        const results = tableResult.map(result => {
            if (result.childResultType === undefined) return {} as AppTableRowData;

            const { massRecomendation, childResultType, value, description } = result;

            const situation = massRecomendation.situationSeverity
                ? <Chip label={massRecomendation.description} className={massRecomendation.situationSeverity} size="small" />
                : massRecomendation.description;

            const chart = <Button onClick={() => handleChartOpen(childResultType, value)} ><AssessmentIcon sx={{ color: "#808080" }} fontSize="large" /></Button>;
            const suffix = resultsTitles[ChildResultType[childResultType]].suffix;

            return {
                description: { value: description },
                now: { value: `${getFormattedNumber(value)} ${suffix}` },
                recomendation: { value: `${getFormattedNumber(Number(massRecomendation.recomendation))} ${suffix}` },
                situation: { value: situation },
                chart: { value: chart }
            } as AppTableRowData;
        });

        return results;
    }, [tableResult, handleChartOpen]);

    useEffect(() => {
        const anthropometricYearAge = 5;
        const isShowAgeMessage = (data.ageInYears ?? 0) <= anthropometricYearAge;
        setIsShowAgeMessage(isShowAgeMessage);
    }, [patient, data.ageInYears])

    return (
        <>
            <AppLoading isLoading={isLoading} />
            <Modal
                aria-labelledby="Gráfico Resultados"
                aria-describedby="Resultado Avaliação Anthropometrica"
                open={chartOpen}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={chartOpen} >
                    <Box sx={style}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography variant="h6" component="h2">
                                Gráfico
                            </Typography>
                            <Box sx={{ width: "780px" }}>
                                <Typography sx={{ textAlign: "center" }}>
                                    {chartTitle.title}
                                </Typography>

                                <Grid container spacing={2} sx={{ mb: 4 }}>
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Chip
                                            label={"Data Nascimento:" + getFormattedDate(patient.birthDate)}
                                            sx={{ mr: 2 }}
                                        />
                                        <Chip
                                            label={`Idade em anos: ${data.ageInYears} ano(s)`}
                                            sx={{ mr: 2 }}
                                        />
                                        <Chip
                                            label={`Idade em meses: ${data.ageInMonths} mese(s)`}
                                            sx={{ mr: 2 }}
                                        />
                                        {isShowAgeMessage &&
                                            <Chip
                                                label={`Idade em dias: ${data.ageInDays} dia(s)`}
                                                sx={{ mr: 2 }}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <LineChart
                                    dataset={dataset}
                                    grid={{ vertical: false, horizontal: true }}
                                    xAxis={[
                                        {
                                            dataKey: 'ageMonth',
                                            valueFormatter: getAgeFormatted,
                                            min: ageMonthRange.minAgeMonth,
                                            max: ageMonthRange.maxAgeMonth,
                                            tickNumber: 24,
                                            tickLabelInterval: (value: number): boolean => {
                                                if (value === 0 || value % 6 === 0) return true;
                                                return false;
                                            }
                                        }
                                    ]}
                                    yAxis={[{
                                        data: yAxis,
                                        valueFormatter: (value) => `${value} ${chartTitle.suffix}`
                                    }]}
                                    series={anthropometricChartSeries}
                                    slotProps={{
                                        legend: {
                                            padding: 8,
                                            position: {
                                                vertical: "top",
                                                horizontal: "middle",
                                            },
                                            itemMarkWidth: 20,
                                            itemMarkHeight: 2,
                                            markGap: 5,
                                            itemGap: 10,

                                        }
                                    }}
                                    height={300}
                                >
                                    <LinePlot />
                                    <MarkPlot />
                                    <ChartsReferenceLine
                                        x={data.ageInMonths?.toString() ?? ""}
                                        label="Idade"
                                        lineStyle={{ stroke: '#808080' }}
                                    />
                                    <ChartsReferenceLine y={yAxisPatientValue.toString()} label={chartTitle.type} lineStyle={{ stroke: '#808080' }} />

                                </LineChart>
                                {isShowAgeMessage &&
                                    <Typography sx={{ textAlign: "left", fontSize: "10px", lineHeight: "14px" }}>
                                        * Valor de referência conforme idade da criança (em dias), segundo Padrões de Crescimento Infantil da Organização Mundial da Saúde (OMS).
                                    </Typography>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>

            {data.bodyComposition.bodyCompositionType !== BodyCompositionType.Bioimpedance &&
                <>
                    <AppFormTitleDivider text="Resultados" iconColor={"#954ff7"} icon={Calculate} />

                    {!isAllMeasures &&
                        <Grid item xs={12}>
                            <Alert severity="info">
                                Sem resultado anthropométrico ainda. Preencha todos os campos de medidas para resultados.
                            </Alert>
                        </Grid>
                    }


                    {!isBmi &&
                        <Grid item xs={12}>
                            <Alert severity="info" sx={{ mt: 2 }}>
                                Sem resultado do IMC ainda. Preencha altura e peso para calculo do IMC.
                            </Alert>
                        </Grid>
                    }

                    {showTableResult && data.patientType !== PatientType.Child &&
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <AppTable rows={getAdultResults()} columns={adultColumnsTable} />
                        </Grid>
                    }

                    {showTableResult && data.patientType === PatientType.Child &&
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <AppTable rows={getChildResults()} columns={childColumnsTable} />
                        </Grid>
                    }
                </>
            }
        </>
    );
};

export default ResultsSection;