import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import AppButton from '../../../../components/forms/AppButton';
import { useNavigate } from 'react-router-dom';

interface AppInterationsPageHeaderActionProps {
    label: string;
    icon: React.ReactNode;
    onClick: () => void;
}

export interface AppInterationsPageHeaderProps {
    title: string;
    subtitle: string;
    action?: AppInterationsPageHeaderActionProps
}

const AppInterationsPageHeader: React.FC<AppInterationsPageHeaderProps> = ({ title, subtitle, action }) => {

    const navigate = useNavigate();

    return (
        <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", flexGrow: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 1 }}>
                <IconButton onClick={() => { navigate(-1) }}>
                    <ArrowBack />
                </IconButton>
                <Typography variant="h5" component="h2" sx={{ fontWeight: "bold" }}>
                    {title}  <Typography component={"span"} sx={{ color: "#ccc" }}> {subtitle}</Typography>
                </Typography>
            </Box>

            {action &&
            <Box>
                <AppButton 
                startIcon={action.icon} 
                variant="contained" 
                color="primary" 
                onClick={action.onClick} 
                label={action.label}
                ></AppButton>
            </Box>
            }
        </Box>
    );
}

export default AppInterationsPageHeader;