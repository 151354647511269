import * as React from 'react';

import { Box, Paper, Typography } from '@mui/material';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import logo from '../../assets/img/nutrifit-logo.png';
import logoMenor from '../../assets/img/nutrifit-logo-menor.png';
import AppOfficeIcon from '../icons/AppOfficeIcon';
import AppPeopleIcon from '../icons/AppPeopleIcon';
import { useSelector } from 'react-redux';
import MenuItem, { MenuItemProps } from './AppMenuItem';
import { LayoutState } from '../../state/reducers/layout.reducer';
import { ROUTES } from '../../constants/routes';

const AppSideBar: React.FC = () => {

    const layout = useSelector((state: any) => state.layout as LayoutState);
    
    const [collapsed, setCollapsed] = React.useState(layout.windowSize.width <= 600);
    
    const HelpLinkItem = ({ icon, title, link="#" }: MenuItemProps) => {
        return (
            <Box sx={{display:"flex", alignItems:"center", gap:1, pt:1, pb:1, width:"100%"}}>
               {icon} 
                <a target="_blank" rel="noreferrer" style={{ textDecoration: "none", color:"inherit" }} href={link}>{title}</a>
            </Box>
        );
    }

    const TextDivisor = ({text}: any) => {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1, mt:1, mb:1 }}>
                <Box style={{ width: "20%", borderBottom: "1px solid #999" }}></Box>
                <Typography variant="body1" component="p" sx={{ color: "#999", fontWeight:"bold" }}>{text}</Typography>
                <Box style={{ width: "20%", borderBottom: "1px solid #999" }}></Box>
            </Box>
        );
    }
    
    React.useEffect(() => {
        setCollapsed(layout.windowSize.width < 1500);
    }, [layout.windowSize.width]);

    return (
        <Paper elevation={0} sx={{ 
            width: collapsed ? "64px": "280px", 
            maxWidth: collapsed ? "64px": "280px", 
            minWidth: collapsed ? "64px": "280px", 
            height:"auto",
            pr: 0, 
            pb: 4, 
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 32,
            borderBottomLeftRadius: 32, 
            flexDirection: "column",
            transition: "width 0.3s",
            display: !layout.windowSize.isMobile ? "flex" : "none",            

            }}>

            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", position:"sticky", top: "32px" }}>
                {!collapsed &&  <img src={logo} alt="logo" style={{ width: "120px", margin: "48px 0", display: "block" }} /> }
                {collapsed && <img src={logoMenor} alt="logo" style={{ width: "32px", margin: "24px 0", display: "block", borderBottom:"1px solid #ccc", paddingBottom:"18px" }} /> }
            </Box>


            <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", flexGrow: 1 }}>
                <Box sx={{ pl: collapsed ? 1 :4, position:"sticky", top:"164px" }}>
                    {!collapsed && <h5 style={{ color: "#999" }}>MENU</h5>}
                    <MenuItem link={ROUTES.MY_PATIENTS} icon={<AppPeopleIcon sx={{fontSize: !collapsed ? "initial": "28px"}} />} title={ !collapsed ? "Meus Pacientes" : ""} />
                    <MenuItem link={ROUTES.MY_CLINICS} icon={<AppOfficeIcon sx={{fontSize: !collapsed ? "initial": "28px"}} />} title={ !collapsed ? "Meus Consultórios" : ""} />
                </Box>

                {layout.windowSize.height > 700 && 
                    <Box sx={{ position:"sticky", bottom:"80px", textAlign: "center", p: 2, m: 2, borderRadius: 4, backgroundColor: "#f7f7f7", opacity: collapsed ? 0 : 1, }}>
                        <Typography component="h2" variant='h6' sx={{ fontWeight: "bold" }} >
                            Precisa de ajuda?
                        </Typography>
                        <Typography component="p" variant='body2' sx={{ color: "#999" }} >
                            Acesse nossos canais de suporte
                        </Typography>

                        <Box sx={{ textAlign: "left", mt:4 }}>
                            <HelpLinkItem icon={<WhatsAppIcon sx={{color:"green", fontSize:"24px"}} />} title="Converse no WhatsApp" link="https://wa.me/5511999999999" />
                            <TextDivisor text="ou" />
                            <HelpLinkItem icon={<MailOutlineIcon sx={{ fontSize:"24px"}}/>} title="Deixe uma mensagem" link="mailto:" />
                        </Box>

                    </Box>
                }
            </Box>
        </Paper>
    );
}

export default AppSideBar;
