import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

interface AppRichTextProps {
  text: string;
  setText: (value: string) => void,
  hasImage?: boolean;
}

export default function AppRichText(props: AppRichTextProps) {

  const { hasImage, text, setText } = props;

  const myColors = [
    "purple",    
    "#785412",    
    "#452632",
    "#856325",
    "#963254",
    "#254563",
    "white",
    "#3366cc",
    "#dc3912",
    "#ff9900",
    "#109618",
    "#990099",
    "#0099c6",
    "#dd4477",
    "#66aa00",
    "#b82e2e",
    "#316395",
    "#3366cc",
    "#994499",
    "#22aa99",
    "#aaaa11",
    "#6633cc",
    "#e67300",
    "#8b0707",
    "#651067",
    "#329262",
    "#5574a6",
    "#3b3eac",
    "#b77322",
    "#16d620",
    "#b91383",
    "#f4359e",
    "#9c5935",
    "#a9c413",
    "#2a778d",
    "#668d1c",
    "#bea413",
    "#0c5922",
    "#743411"
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      hasImage ? ["link", "image" ] : [ "link" ],
      [{ color: myColors }],
      [{ background: myColors }]
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align"
  ];

  const handleProcedureContentChange = (content: any) => {
    console.log(content);
    setText(content);
  };

  return (
    <>
      {console.log(text)}
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={text}
        onChange={handleProcedureContentChange}
      />
    </>
  );
}