import { Avatar, Box, Divider, Fab, Grid, Typography } from "@mui/material";
import CameraAlt from "@mui/icons-material/CameraAlt";
import React, { FormEvent, useEffect, useState, ChangeEvent, useRef } from "react";
import { Delete, Save } from "@mui/icons-material";
import RefreshIcon from '@mui/icons-material/Refresh';
import logo from '../../assets/img/nutrifit-logo.png';
import * as Yup from 'yup';
import api from "../../services/api";
import FormService, { FormErrors } from "../../services/form.service";
import { Nutricionist } from "../../domain/interfaces/Nutricionist";
import { useAppToast } from "../../hooks/core/AppToastContextProvider";
import { useAuth, User } from "../../hooks/core/AuthProvider";
import AppTextField from "../../components/forms/AppTextField";
import AppErrorList from "../../components/forms/AppErrorList";
import AppMaskedTextField from "../../components/forms/AppMaskedTextField";
import AppButton from "../../components/forms/AppButton";
import AppMainLayout from "../../components/layout/AppMainLayout";
import AppMainHeader from "../../components/layout/AppMainHeader";
import AppLoading from "../../components/utils/AppLoading";
import AppCard from "../../components/layout/AppCard";
import AppRichText from "../../components/forms/AppRichText";

const baseUrlImage = "http://localhost:5000/api/v1/health/profile-image?nameFile=";

const formSchema = Yup.object({
    name: Yup
        .string()
        .required('Obrigatório informar o nome.')
        .max(100, 'O campo nome deve ter no máximo 100 caracteres'),
    crn: Yup
        .string()
        .max(50, 'O campo CRN deve ter no máximo 50 caracteres'),
    phoneNumber: Yup
        .string()
        .required('Obrigatório informar o telefone.')
        .min(10, 'O telefone deve ter no mínimo 10 caracteres.')
        .max(20, 'O telefone deve ter no máximo 20 caracteres.'),
    document: Yup
        .object()
        .required("Obrigatório informar o Documento."),
});


const MyProfilePage: React.FC = () => {
    const { user, updateUser } = useAuth();
    const [nutricionist, setNutricionist] = useState({} as Nutricionist);
    const { addToast } = useAppToast();

    const [formErrors, setFormErrors] = useState({} as FormErrors);
    const [errors, setErrors] = useState([] as string[]);
    const [isLoading, setIsLoading] = useState(true);
    const profileImageInputRef = useRef<HTMLInputElement>(null);
    const logoInputRef = useRef<HTMLInputElement>(null);

    const [urlLogo, setUrlLogo] = useState(null as string | null);
    const [urlProfileImage, setUrlProfileImage] = useState(null as string | null);

    const formService = new FormService(nutricionist, setNutricionist, setErrors, setFormErrors);

    // TODO: Acredito que essa implementação deve ser feita no BACKEND
    const getDefaultSignature = (nutricionist: Nutricionist): string => {
        let signatureText = `<h3>${nutricionist.name}</h3><h5><br></h5>` +
            `<h5><strong>E-mail:</strong> ${nutricionist.email}</h5>`;

        signatureText += nutricionist.crn !== null && nutricionist.crn !== undefined ? `<h5><strong>CRN:</strong>${nutricionist.crn}</h5>` : ``;

        return signatureText;
    };

    const getNutricionist = async () => {
        setIsLoading(true);
        const response = await api.get(`/nutricionists/${user?.id}`);

        if (response.status === 200) {
            let nutricionistApi = response.data as Nutricionist;

            if (nutricionistApi.signatureText == null) {
                const signatureText = getDefaultSignature(nutricionistApi);
                nutricionistApi = {...nutricionistApi, signatureText };
            }

            setUrlLogo(nutricionistApi.logoImage);
            setUrlProfileImage(nutricionistApi.profileImage);

            setNutricionist(nutricionistApi);
            setIsLoading(false);
            
            return;
        }

        setIsLoading(false);
        addToast({ title: "Erro", description: "Erro ao buscar dados do nutricionista", type: "error" });
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        formService.cleanErrors();

        try {
            await formSchema.validate(nutricionist, { abortEarly: false });

            const response = await api.put(`/nutricionists/${user?.id}`, nutricionist);
            if (response.status !== 200) {
                throw response.data;
            }

            const userUpdated = {
                ...user,
                name: nutricionist.name,
                phone: nutricionist.phoneNumber
            } as User;

            updateUser(userUpdated);

            addToast({
                title: "Atualização Realizada",
                description: "Dados Atualizados com Sucesso.",
                type: "success"
            });

        } catch (err) {
            formService.handleErros(err);
        }

        setIsLoading(false);
    }

    const handleProfileImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const data = new FormData();
            var file = e.target.files[0];
            data.append('profileImage', file);
            let response = await api.patch(`/nutricionists/${user.id}/profile-image`, data);

            if (response.status === 200) {
                const profileImage = `${response.data.profileImage}&t=${new Date().getTime()}`;

                setNutricionist({
                    ...response.data,
                    profileImage: profileImage
                });

                setUrlProfileImage(profileImage);

                updateUser({
                    ...user,
                    profileImage: profileImage
                });

                addToast({
                    type: 'success',
                    title: 'Avatar atualizado com sucesso.',
                });

                return;
            }

            addToast({
                type: 'error',
                title: 'Houve uma falha na atualização da foto do perfil. Verifique!',
            });
        }
    };

    const handleLogoChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const data = new FormData();
            var file = e.target.files[0];
            data.append('logoImage', file);
            let response = await api.patch(`/nutricionists/${user.id}/logo-image`, data);

            if (response.status === 200) {
                const logoImage = `${response.data.logoImage}&t=${new Date().getTime()}`;

                setNutricionist({
                    ...response.data,
                    logoImage: logoImage
                });

                setUrlLogo(logoImage);

                addToast({
                    type: 'success',
                    title: 'Logo atualizada com sucesso.',
                });

                return;
            }

            addToast({
                type: 'error',
                title: 'Houve uma falha na atualização da logo. Verifique!',
            });
        }
    };

    const handleRemoveLogo = async (event: FormEvent<HTMLButtonElement>) => {
        setIsLoading(true);
        formService.cleanErrors();

        try {
            var updatedNutricionist = { ...nutricionist, logoImage: null };
            const response = await api.put(`/nutricionists/${user?.id}`, updatedNutricionist);
            if (response.status !== 200) {
                throw response.data;
            }

            setUrlLogo(null);

            addToast({
                title: "Logo removida com sucesso.",
                type: "success"
            });

        } catch (err) {
            formService.handleErros(err);
        }

        setIsLoading(false);
    }

    const handleSignatureText = (value: string): void => {
        setNutricionist({ ...nutricionist, signatureText: value });
    };

    const handleProfileImageButtonClick = () => {
        if (profileImageInputRef)
            profileImageInputRef.current?.click();
    };

    const handleLogoButtonClick = () => {
        if (logoInputRef)
            logoInputRef.current?.click();
    };

    useEffect(() => {
        getNutricionist();
    }, [])

    return (
        <AppMainLayout>
            <AppLoading isLoading={isLoading} />
            <AppMainHeader hasBreadCrumbs={false} />
            <AppCard >
                <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                    <Avatar
                        alt="Remy Sharp"
                        src={urlProfileImage !== null ? `${baseUrlImage}${urlProfileImage}` : ''}
                        sx={{ width: 160, height: 160, border: "solid 3px #D9D9D9" }}
                    />
                    <Fab color="primary" size="small" aria-label="edit" onClick={handleProfileImageButtonClick}
                        sx={{
                            position: "absolute",
                            ml: "115px"
                        }} >
                        <CameraAlt />
                    </Fab>
                    <input accept=".jpg,.jpeg,.png" ref={profileImageInputRef} type="file" onChange={handleProfileImageChange} style={{ display: "none" }} />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: "flex", width: "100%", mt: 4, justifyContent: "center" }}>
                        <Typography variant="h5" component="h3" sx={{ textAlign: "center", fontWeight: "bold" }}>Dados Cadastrais</Typography>
                    </Box>
                    <Divider sx={{ mt: 1, mb: 4 }} />
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <AppTextField
                                    fullWidth
                                    name="name"
                                    type="text"
                                    autoFocus={true}
                                    label="Nome Completo"
                                    value={nutricionist.name}
                                    onChange={(e) => formService.setInputValue("name", e.target.value)}
                                    errorMessage={formErrors.name}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <AppTextField
                                    fullWidth
                                    name="email"
                                    disabled
                                    type="text"
                                    autoFocus={true}
                                    label="E-mail"
                                    value={nutricionist.email}
                                    onChange={(e) => formService.setInputValue("email", e.target.value)}
                                    errorMessage={formErrors.email}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
                            <Grid item md={3} xs={6}>
                                <AppMaskedTextField
                                    id="document"
                                    fullWidth
                                    mask="999.999.999-99"
                                    label="CPF"
                                    disabled
                                    name="document"
                                    value={nutricionist.document?.value}
                                    onChange={(e) => setNutricionist({ ...nutricionist, document: { ...nutricionist.document, value: e.target.value } })}
                                    errorMessage={formErrors.cpf}
                                />
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <AppMaskedTextField
                                    id="phoneNumber"
                                    fullWidth
                                    mask="(99) 99999-9999"
                                    label="Telefone"
                                    name="phoneNumber"
                                    value={nutricionist.phoneNumber}
                                    onChange={(e) => formService.setInputValue("phoneNumber", e.target.value)}
                                    errorMessage={formErrors.phoneNumber}
                                />
                            </Grid>
                            <Grid item md={3} xs={6}>
                                <AppTextField
                                    id="crn"
                                    fullWidth
                                    label="CRN"
                                    name="crn"
                                    value={nutricionist.crn}
                                    onChange={(e) => formService.setInputValue("crn", e.target.value)}
                                    errorMessage={formErrors.crn}
                                />
                            </Grid>
                        </Grid>
                        <AppErrorList errors={errors} />
                    </Box>
                    <Box sx={{ display: "flex", mt: 4 }}>
                        <Typography component="h3" variant="h5" sx={{ textAlign: "center", width: "100%", fontWeight: "bold", mt: 2 }}>Assim que ficará sua identidade visual nos cabeçalhos de documentos:</Typography>
                    </Box>
                    <Divider sx={{ mt: 1, mb: 4 }} />
                    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", mt: 2 }}>
                        <Box sx={{ display: "flex" }}>
                            {urlLogo !== null && <img style={{ height: "60px" }} src={`${baseUrlImage}${urlLogo}`} alt="logo" />}
                            {urlLogo === null && <img style={{ width: "200px", height: "60px" }} src={logo} alt="logo" />}
                            <Box sx={{ ml: 2, display: "flex", flexDirection: "column", justifyContent: "center" }} >
                                <div dangerouslySetInnerHTML={{ __html: nutricionist.signatureText || "" }}></div>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", mt: 2, gap: 1, justifyContent: "center" }}>
                        <Fab variant="extended" color="info" size="small" aria-label="edit" onClick={handleLogoButtonClick}
                            sx={{
                            }} >
                            <RefreshIcon />
                            Atualizar Logo
                        </Fab>
                        <input accept=".jpg,.jpeg,.png" ref={logoInputRef} type="file" onChange={handleLogoChange} style={{ display: "none" }} />

                        <Fab variant="extended" color="error" size="small" aria-label="edit" onClick={handleRemoveLogo}
                            sx={{
                            }} >
                            <Delete />
                            Remover Logo
                        </Fab>
                    </Box>
                    <Box sx={{ mt: 5, display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        <AppRichText hasImage={true} text={nutricionist.signatureText || ""} setText={handleSignatureText} />
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", mt: 2 }}>
                        <AppButton
                            variant='contained'
                            type="submit"
                            startIcon={<Save />}
                            label="Salvar Alterações"
                            fullWidth={false}
                        />
                    </Box>
                </form>
            </AppCard>
        </ AppMainLayout>
    );

};

export default MyProfilePage;

