import { SkinfoldProtocolType } from "../enums/SkinfoldProtocolType";
import { BioimpedanceFields, SkinfoldFields, WeltmanFields } from "../store/types.d";

function isUsed(skinfoldProtocolType: SkinfoldProtocolType, protocols: SkinfoldProtocolType[]) : boolean {
    return protocols.includes(skinfoldProtocolType);
}

export const skinfoldProtocolFields: SkinfoldFields = {
    biceps: {
        name: "biceps",
        label: "Bícipes",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols =  [
                SkinfoldProtocolType.FourSkinfoldDurninAndWomersley,
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    triceps: {
        name: "triceps",
        label: "Tríceps",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols =  [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.ThreeSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.FourSkinfoldDurninAndWomersley,
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    abdominal: {
        name: "abdominal",
        label: "Abdominal",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.ThreeSkinfoldGuedes
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    suprailiaca: {
        name: "suprailiaca",
        label: "Suprailíaca",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.ThreeSkinfoldGuedes,
                SkinfoldProtocolType.ThreeSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.FourSkinfoldDurninAndWomersley
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    middleAxillary: {
        name: "middleAxillary",
        label: "Axilar Média",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    subscapularis: {
        name: "subscapularis",
        label: "Subescapular",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.FourSkinfoldDurninAndWomersley
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    thorax: {
        name: "thorax",
        label: "Tórax",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.ThreeSkinfoldGuedes
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    thigh: {
        name: "thigh",
        label: "Coxa",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [
                SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard,
                SkinfoldProtocolType.ThreeSkinfoldJacksonAndPollockAndWard
            ];
            return isUsed(skinfoldProtocolType, protocols);
        }
    },
    medialCalf: {
        name: "medialCalf",
        label: "Panturrilha Medial",
        isUsed: (skinfoldProtocolType: SkinfoldProtocolType): boolean => {
            const protocols = [ ] as SkinfoldProtocolType[];
            return isUsed(skinfoldProtocolType, protocols);
        }
    }
};

export const weltmanProtocolFields: WeltmanFields = {
    abdomen1: {
        name: "abdomen1",
        label: "Abdômen 1",
        suffix: " cm"
    },
    abdomen2: {
        name: "abdomen2",
        label: "Abdômen 2",
        suffix: " cm"
    }
};

export const bioimpedanceFields: BioimpedanceFields = {
    bmi: {
        name: "bmi",
        label: "IMC",
        suffix: ""
    },
    fatMassPercent: {
        name: "fatMassPercent",
        label: "% Massa Gorda",
        suffix: "%",
        minValue: 0,
        maxValue: 100
    },
    fatMass: {
        name: "fatMass",
        label: "Massa Gorda",
        suffix: " Kg"
    },
    leanMassPercent: {
        name: "leanMassPercent",
        label: "% Massa Magra",
        suffix: "%",
        minValue: 0,
        maxValue: 100
    },
    leanMass: {
        name: "leanMass",
        label: "Massa Magra",
        suffix: " Kg"
    },
    bodyWaterPercent: {
        name: "bodyWaterPercent",
        label: "% Agua Corporal",
        suffix: "%",
        minValue: 0,
        maxValue: 100
    },
    bodyWater: {
        name: "bodyWater",
        label: "Agua Corporal",
        suffix: " L",
        placeholder: "de 0 até 100",
        minValue: 0,
        maxValue: 100
    },
    boneWeight: {
        name: "boneWeight",
        label: "Peso Ósseo",
        suffix: " Kg"
    },
    residualWeight: {
        name: "residualWeight",
        label: "Peso Residual",
        suffix: " Kg"
    },
    muscleWeight: {
        name: "muscleWeight",
        label: "Peso Muscular",
        suffix: " Kg"
    },
    visceralFatPercent: {
        name: "visceralFatPercent",
        label: "% Gordura Viceral",
        suffix: "%",
        placeholder: "de 0 até 100",
        minValue: 0,
        maxValue: 100
    },
    metabolicAge: {
        name: "metabolicAge",
        label: "Idade Metabólica",
        suffix: ""
    },
    percentSkeletalMuscles: {
        name: "skeletalMusclesPercent",
        label: "% Músculos esqueléticos",
        suffix: "%",
        placeholder: "de 0 até 100",
        minValue: 0,
        maxValue: 100
    }
};
