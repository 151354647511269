import { Anthropometric } from "../../pages/MyPatients/Interations/Anthropometric/types/Anthropometric";
import { MaritalStatus } from "../enums/MaritalStatus";
import { Clinic } from "./Clinic";

export interface Patient {
  id?: string;
  name: string;
  birthDate?: string;
  gender?: Gender;
  email: string;
  phone?: string;
  address?: PatientAddress;
  isPregnant?: boolean;
  numberOfPregnancyWeeks?: number,
  maritalStatus?: MaritalStatus;
  profession?: string;
  observation?: string;
  clinicId?: string | unknown;
  clinic?: Clinic;
  isActive: boolean;
  anthropometrics?: Anthropometric[]
}


interface PatientAddress {
  street?: string;
  number?: string;
  complement?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

export enum Gender {
  Male = 1,
  Female = 2
}