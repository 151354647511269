export const ROUTE_ACTIONS = {
    EDIT: "editar",
    VIEW: "visualizar",
    ADD: "adicionar"
}


export const ROUTES = {
    LOGIN: "/login",
    REGISTER: "/cadastro",
    REGISTER_SUCCESS: "/cadastro-sucesso",
    FORGOT_PASS: "/esqueci-senha",
    FORGOT_PASS_SUCESS: "/esqueci-senha-sucesso",
    RESET_PASS: "/resetar-senha",
    RESET_PASS_SUCCESS: "/resetar-senha-sucesso",
    MY_PROFILE: "/meu-perfil",
    MY_PATIENTS: "/meus-pacientes",
    PATIENT_DETAIL: "/meus-pacientes/:id/:action",
    PATIENT_NEW_ANAMNESIS: "/meus-pacientes/:patientId/nova-anamnese",
    PATIENT_ANAMNESIS: "/meus-pacientes/:patientId/anamnese/:anamnesisId",
    PATIENT_NEW_ANTHROPOMETRIC: "/meus-pacientes/:patientId/nova-antropometria",
    MY_CLINICS: "/meus-consultorios",
    CLINIC_DETAIL: "/meus-consultorios/:id/:action",
    CONFIRMAR_EMAIL: "/confirmar-email",
    HOME: "/"
}