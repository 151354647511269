import * as React from 'react';
import { Box, Divider, Paper, useTheme } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CloseIcon from '@mui/icons-material/Close';
import AppSearchIcon from '../icons/AppSearchIcon';
import logo from '../../assets/img/nutrifit-logo.png';
import { useSelector } from 'react-redux';
import AppOfficeIcon from '../icons/AppOfficeIcon';
import AppPeopleIcon from '../icons/AppPeopleIcon';
import MenuItem from './AppMenuItem';
import { LayoutState } from '../../state/reducers/layout.reducer';


const AppTopBar: React.FC = () => {
  const theme = useTheme();
  const [searchActive, setSearchActive] = React.useState(false);
  const [sideMenuActive, setSideMenuActive] = React.useState(false);
  const layout = useSelector((state: any) => state.layout as LayoutState);

  const topBarStyle = { position: "sticky", zIndex:2, width: "100%", top: 0, height: "64px", boxShadow: "0px 2px 4px rgba(0,0,0,0.1)", display: layout.windowSize.isMobile ? "block" : "none" }
  const mainContainerStyle = { width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", pt: 2, pb: 2, position: "relative" };
  const logoStyle = { display: 'flex', justifyContent: "center", alignItems: "center" };
  const sideMenuStyle = {
    position: "absolute",
    right: 0,
    top: 0,
    marginTop: "64px",
    backgroundColor: "#fff",
    zIndex: sideMenuActive ? 3 : -100,
    width: "200px",
    borderTop: "1px solid #e6e6e6",
    opacity: sideMenuActive ? 1 : 0,
    minHeight: "calc(100vh - 64px)",
    boxShadow: "0 5px 5px #ccc",
    transition: "opacity .3s ease-in-out"
  };

  React.useEffect(() => {
    setSideMenuActive(false);
  }, [layout.windowSize])


  React.useEffect(() => {
    if (sideMenuActive) {
      document.body.style.overflow = "hidden";
    }
    else {
      document.body.style.overflow = 'auto';
    }

  }, [sideMenuActive])


  return (
    <>
      <Paper sx={topBarStyle}>
        <Box sx={mainContainerStyle}>
          <Box sx={logoStyle}>
            <img src={logo} alt="logo" style={{ width: "120px", marginLeft: "32px", display: "block" }} />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 2, width: "70%" }}>
            <Box>
              <AppSearchIcon sx={{ fontSize: "18px", color: "#4d4d4d", cursor: "pointer" }} onClick={() => setSearchActive(true)} />
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box  onClick={() => setSideMenuActive(!sideMenuActive)}>
              {!sideMenuActive &&
                <MenuOpenIcon sx={{ fontSize: "32px", color: "#4d4d4d", "&:hover":{color: theme.palette.secondary.main}, cursor: "pointer", marginRight: "16px" }} />
              }

              {sideMenuActive &&
                <CloseIcon sx={{ fontSize: "32px", color: "#4d4d4d", "&:hover":{color: theme.palette.secondary.main}, cursor: "pointer", marginRight: "16px" }} />
              }
            </Box>
          </Box>

        </Box>


      </Paper>
      <Box sx={sideMenuStyle}>
        <Box>
          <MenuItem border={"left"} icon={<AppOfficeIcon />} link='/meus-consultorios' title='Meus Consultórios' />

          <MenuItem border={"left"} icon={<AppPeopleIcon />} link='/meus-pacientes' title='Meus Pacientes' />
        </Box>
      </Box>
    </>

  );
}

export default AppTopBar;
