import React from 'react';

import { Button, ButtonProps, Typography } from '@mui/material';

interface ButtonFielProps extends ButtonProps{
    label?: string
}

const  AppButton: React.FC<ButtonFielProps> = (props) => {

    const { label, ...rest } = props;
    return (
        <Button
            {...rest}
            sx= {{ 
                borderRadius: "12px", 
                mt: "6px", pl: 2, pr: 2, 
                boxShadow: "none", 
                textTransform: "none",
                "&:hover": {
                    boxShadow: "none",
                    backgroundColor: props.variant === 'contained' ? "#6B9D30" : "initial"
                },
                ...rest.sx                
            }}         
        >
            <Typography sx={{ fontWeight: "bold", lineHeight: "28px", fontSize: "12px", textTransform:'none' }}>{label}</Typography>
    </Button>


    );
}

export default AppButton;