import { Box,  MenuItem, Select, SelectProps, SxProps, Theme} from '@mui/material';
import React  from 'react'
import AppInputError from './components/InputErro';

export type AppSelectProps = SelectProps<any> & {
  options: AppSelectOption[];
  errorMessage?: string;
  boxSx?: SxProps<Theme>;
}

export interface AppSelectOption {
  value: any;
  label: string;
}

const AppSelect: React.FC<AppSelectProps> = (props) => {
  const { errorMessage, boxSx } = props;
  const hasError = errorMessage !== undefined && errorMessage !== null && errorMessage !== "";
  
  return (
    <Box sx={{ position:"relative", ...boxSx}}>
      <Select
        {...props}
        labelId={`row-select-${props.name}`}
        id={`row-select-${props.name}`}
        error={hasError}
        value={props.value||""}
      >
        {props.options.map((option, index) => {
          return (
            <MenuItem selected={props.value === option.value} key={index} value={option.value}>{option.label}</MenuItem>
          );
        })}
      </Select>
      <AppInputError error={errorMessage} />
    </Box>
  )
}

export default AppSelect;