import { ThemeOptions, createTheme } from "@mui/material/styles";

const modo = localStorage.getItem("modo") === "dark" ? "dark" : "light";

const themeOptions: ThemeOptions = {
  palette: {
    mode: modo,
    primary: {
      main: "#FF9800",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#43a047",
      light: "#43a047",
    },
    action: {
      hover: "#FFE0B2",
    },
    background: {
      default: modo === "dark" ? "#303030" : "#EBEBEB",
      paper: modo === "dark" ? "#424242" : "#ffffff",
    },
    text: {
      primary: modo === "dark" ? "#ffffff" : "#000000",
      secondary: modo === "dark" ? "#ffffff" : "#00000099",
    },
    
    // borderRadius: 10,
  },
  typography: {
    fontFamily: 'Comfortaa, "Helvetica", "Arial", sans-serif',
    fontSize: 12,
  },
  components: {
    MuiDialog: {
      defaultProps: {
        onClick: (e) => e.stopPropagation(),
        BackdropProps: {
          sx: {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          alignItems: "center",
          borderRadius: 10,
          // paddingRight: 4,
          "& legend": {
            visibility: "visible"
          }
        },

      },
    }
  }
};




export default createTheme(themeOptions);