import React, { ChangeEvent } from "react";
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

import { useTheme } from '@mui/material/styles';
import AppInputError from "./components/InputErro";
import AppTooltip from "./AppTooltip";

interface AppRadioButtonProps {
  name: string;
  label: string;
  value?: any;
  orientation?: "row" | "column";
  compact?: boolean;
  boldLabel?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  options: AppRadioButtonOption[];
  errorMessage?: string;
  tooltip?: string | JSX.Element;
  tooltipPosition?: "top" | "right" | "bottom" | "left" | undefined;
}

interface AppRadioButtonOption {
  value: any;
  label: string;
}

const AppRadioButton: React.FC<AppRadioButtonProps> = (props) => {
  const theme = useTheme();
  const [hideError, setHideError] = React.useState(false);
  
  return (
    <Box>
      <Box sx={{display:"flex"}}>
        <FormLabel id={`row-radio-buttons-group-${props.name}`} sx={{
          color: theme.palette.text.secondary,
          fontWeight: props.boldLabel ? "bold" : "normal"
        }}>
          {props.label}
        </FormLabel>
        <AppTooltip
          position={props.tooltipPosition}
        >{props.tooltip}
        </AppTooltip>
      </Box>
      
      <RadioGroup
        row={props.orientation === "row"}
        aria-labelledby={`row-radio-buttons-group-${props.name}`}
        name={props.name}
        value={props.value}
        onChange={(e)=>{
          setHideError(true);
          props.onChange && props.onChange(e)
        }}

      >
        {props.options.map((option, index) => {
          const checked = props.value ? option.value === props.value : false;
          const radioProps = checked ? {checked: true} : {};
          return (
            <FormControlLabel 
              key={index} 
              value={option.value} 
              label={option.label} 
              sx={{color: theme.palette.text.secondary}}
              control={
                <Radio 
                  size={props.compact ? "small" : "medium"}
                  sx={{color: theme.palette.text.secondary}}
                  {...radioProps}
                />} 
            />
          );
        })}
      </RadioGroup>
      {!hideError && <AppInputError error={props.errorMessage} />}

    </Box>
  );
}

export default AppRadioButton;