import { Gender } from "../../../../../../domain/interfaces/Patient";
import { BodyComposition } from "../../types/BodyComposition/BodyComposition";
import { BodyCompositionItemResult } from "../../types/BodyComposition/BodyCompositionItemResult";
import { IAnthropometricProtocolService } from "../types";

export type WeltmanProtocolCalculateType = {
    weight: number;
    height: number;
    data: BodyComposition;
    birthDate: string;
    gender: Gender;
}

export default class BioimpedanceService implements IAnthropometricProtocolService {

    private bodyComposition: BodyComposition;

    public constructor(bodyComposition: BodyComposition) {
        this.bodyComposition = bodyComposition;
    }

    public calculate(): BodyComposition {
        return {
            ...this.bodyComposition,
            bodyCompositionResult: {
                results: [] as BodyCompositionItemResult[]
            }
        } as BodyComposition
    }

    public isAllMeasures(): boolean {
        return true;
    }

    public isWeigthAndHeight(): boolean {
        return true;
    };
}