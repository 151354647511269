import React, { ChangeEvent, useCallback, useState } from "react";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import { ArrowBack, Save } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { PieChart } from "@mui/x-charts/PieChart";

import AppMainLayout from "../../components/layout/AppMainLayout";
import AppCard from "../../components/layout/AppCard";
import AppMainHeader from "../../components/layout/AppMainHeader";

import { useAuth } from "../../hooks/core/AuthProvider";
import { useAppToast } from "../../hooks/core/AppToastContextProvider";
import { Gender, Patient } from "../../domain/interfaces/Patient";
import api from "../../services/api";
import AppButton from "../../components/forms/AppButton";
import AppLoading from "../../components/utils/AppLoading";
import InfoPatient from "./components/InfoPatient";
import ButtonAvaliationAdd from "./components/ButtonAvaliationAdd";
import AppAcordion from "../../components/layout/AppAcordion";
import AppUtensilsIcon from "../../components/icons/AppUtensilsIcon";
import AppFileMedicalIcon from "../../components/icons/AppFileMedicalIcon";
import CardInfo from "./components/CardInfo";
import AppBookMedicalIcon from "../../components/icons/AppBookMedicalIcon";
import AppWeigthScaleIcon from "../../components/icons/AppWeigthScaleIcon";
import AppBoltIcon from "../../components/icons/AppBoltIcon";
import AppPlusIcon from "../../components/icons/AppPlusIcon";
import AppModal from "../../components/layout/AppModal";
import PatientForm from "./components/PatientForm";
import AppTextField from "../../components/forms/AppTextField";
import PatientService from "../../services/patient.service";
import { usePatientStore } from "./Stores/PatientStore/PatientStore";
import { getFormattedDate } from "../../utils/dateUtils";
import { AnamnesisData } from "./Stores/AnamnesisStore/types.d";


const genderDescriptions = [
    "Não informado",
    "Masculino",
    "Feminino",
    "Outro"
];

const PatientDetailPage: React.FC = () => {

    const { id } = useParams();
    const { user } = useAuth();
    const { addToast } = useAppToast();
    const { patient, setPatient } = usePatientStore();
    const [anamnesisList, setAnamnesisList] = useState([] as AnamnesisData[]);
    const navigate = useNavigate();

    const [modalEditPatientIsOpen, setModalEditPatientIsOpen] = React.useState(false);
    const [isObservationEdited, setIsObservationEdited] = useState(false);

    const getPatient = useCallback(async () => {
        const response = await api.get(`/nutricionists/${user.id}/patients/${id}`);

        if (response.status === 200) {
            var patient = response.data as Patient;
            patient.clinicId = patient.clinic?.id;
            setPatient(patient);
            return;
        }

        addToast({ title: "Erro", description: "Erro ao buscar dados do paciente", type: "error" });

    }, [setPatient, addToast, id, user.id]);

    const getAnamnesis = async () => {
        const response = await api.get(`/anamnesis/patients/${id}`)

        if(response.status === 200){
            var list = response.data as AnamnesisData[]

            setAnamnesisList(list);
        }
    }

    const handleChangeObservation = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsObservationEdited(true);
        setPatient({ ...patient, observation: e.target.value });
    }

    const handleUpdatedObservation = async () => {
        if (!isObservationEdited) {
            addToast({
                title: "Observação não foi alterada!",
                description: "Não houve alteração nos detalhes do paciente.",
                type: "info"
            })
            return;
        }
        try {
            var response = await PatientService.patientUpdate(patient, user?.id);
            if (response.status === 200) {
                addToast({
                    title: "Observação Atualizada",
                    description: "Observação atualizado com sucesso!",
                    type: "success"
                });
            }
            setIsObservationEdited(false);

        } catch (errors) {
            addToast({
                title: "Falha na atualização",
                description: "Houve uma falha na Atualização da observação, por favor tente novamente.",
                type: "error"
            });
        }

    }

    const handleUpdatedPatient = async () => {
        setModalEditPatientIsOpen(false);
        await getPatient();
    }

    const getAgeFromDateOfBirthday = (dateOfBirth: any): number => {
        return moment().diff(dateOfBirth, 'years');
    }

    const getGestationDescription = (numberOfPregnancyWeeks: number | undefined): string => {
        if (numberOfPregnancyWeeks === undefined)
            return "--";

        if (numberOfPregnancyWeeks > 42)
            return "--";
        if (numberOfPregnancyWeeks === 1)
            return "1 semana de gravidez."

        return `${numberOfPregnancyWeeks} semanas de gravidez.`;
    }

    const getGender = (gender: Gender | undefined): string => {
        if (gender === undefined)
            return "--";

        return genderDescriptions[gender];
    }

    React.useEffect(() => {
        getPatient();
        // getAnamnesis();
    }, [id]);

    return (
        <AppMainLayout>
            <AppLoading isLoading={false} />
            <AppMainHeader hasBreadCrumbs={true} />

            <AppCard>

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", gap: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 1 }}>
                        <IconButton onClick={() => { navigate(-1) }} sx={{ backgroundColor: "#F2F2F2" }}>
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h5" component="h3" sx={{ fontWeight: "bold", mr: 2 }}>
                            {patient.name}
                        </Typography>
                        <Chip
                            label={patient.isActive ? "Ativo" : "Inativo"}
                            sx={patient.isActive ? { backgroundColor: "#63d168" } : {}} />
                    </Box>
                    <AppButton
                        variant="outlined"
                        type="button"
                        startIcon={<PersonIcon />}
                        label="Dados Cadastrais"
                        fullWidth={false}
                        onClick={() => setModalEditPatientIsOpen(true)}
                    />
                </Box>
                <Box sx={{ mt: 5, display: "flex", alignItems: "center", flexWrap: "wrap", gap: 4 }}>
                    <InfoPatient title="Última Interação" data="01/01/2024" />
                    <InfoPatient title="Idade" data={`${getAgeFromDateOfBirthday(patient.birthDate)} anos`} />
                    <InfoPatient title="Sexo" data={getGender(patient.gender)} />
                    {patient.isPregnant &&
                        <InfoPatient title="Gestação" data={getGestationDescription(patient.numberOfPregnancyWeeks)} />
                    }
                    <InfoPatient title="Consultório" data={patient.clinic?.name ?? "--"} />
                </Box>

                <Box sx={{ mt: 5, display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "flex-end" }}>
                    <AppTextField
                        fullWidth
                        multiline
                        rows={4}
                        name="observacoes"
                        type="text"
                        label="Observações"
                        value={patient.observation}
                        boxSx={{ width: "100%", mb: 2 }}
                        onChange={handleChangeObservation}
                    />
                    <AppButton
                        variant="contained"
                        type="button"
                        startIcon={<Save />}
                        label="Salvar Observação"
                        fullWidth={false}
                        onClick={handleUpdatedObservation}
                    />
                </Box>

                <AppAcordion
                    defaultExpanded={true}
                    title="Adicionar Interação"
                    sx={{ mt: 3 }}
                    titleIcon={<AppPlusIcon sx={{ fontSize: "22px", mr: 1 }} />}
                >
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, p: "16px", borderRadius: "12px", backgroundColor: "#F2F2F2" }}>
                        <ButtonAvaliationAdd icon={<AppFileMedicalIcon />} label="Anamnese" onClick={() => navigate(`/meus-pacientes/${id}/nova-anamnese`)} />
                        <ButtonAvaliationAdd icon={<AppWeigthScaleIcon />} label="Avaliação Antropométrica" onClick={() => navigate(`/meus-pacientes/${id}/nova-antropometria`)} />
                        <ButtonAvaliationAdd icon={<AppBoltIcon />} label="Gasto Energético" />
                        <ButtonAvaliationAdd icon={<AppBookMedicalIcon />} label="Recordatório Alimentar" />
                        <ButtonAvaliationAdd icon={<AppUtensilsIcon />} label="Plano Alimentar" />
                    </Box>
                </AppAcordion>

                <AppAcordion
                    defaultExpanded={true}
                    title="Plano Alimentar - 01/01/2024"
                    titleIcon={<AppUtensilsIcon sx={{ fontSize: "22px", mr: 1 }} />}
                    sx={{ mt: 3 }}>
                    <Box sx={{
                        borderRadius: "12px",
                        backgroundColor: "#F2F2F2",
                        p: "16px",
                        display: "flex", flexDirection: "row", gap: 1,
                    }}>
                        <Box sx={{
                            gap: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <Chip label="Todos os dias" />
                            <Chip label="1700 Kcal" />
                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <PieChart
                                sx={{ mt: 3 }}
                                series={[
                                    {
                                        data: [
                                            { id: 0, value: 10, label: '% Proteínas' },
                                            { id: 1, value: 15, label: '% Carboidratos' },
                                            { id: 2, value: 20, label: '% Lipídeos' },
                                        ],
                                    },
                                ]}
                                width={300}
                                height={200}
                                margin={{ right: 150 }}
                            />
                        </Box>

                    </Box>
                </AppAcordion>

                <AppAcordion
                    defaultExpanded={true}
                    title="Ultimas Anamneses"
                    sx={{ mt: 3 }}
                    titleIcon={<AppFileMedicalIcon sx={{ fontSize: "22px", mr: 1 }} />}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1, p: "16px",
                        borderRadius: "12px",
                        backgroundColor: "#F2F2F2"
                    }}>
                    {anamnesisList.map(anamnese => {
                        return <CardInfo 
                        link={`/meus-pacientes/${id}/anamnese/${anamnese.id}`}
                        key={anamnese.id} 
                        title={`Anamnese realizada dia ${new Date(anamnese.creationDate).toLocaleString()}`} 
                        icon={<AppFileMedicalIcon 
                        sx={{ fontSize: "18px", mr: 1 }} />
                    } />
                    })}
                        
                       
                    </Box>
                </AppAcordion>
                
                { patient.anthropometrics && 
                    <AppAcordion
                        defaultExpanded={true}
                        title="Ultimas Antropometrias"
                        sx={{ mt: 3 }}
                        titleIcon={<AppWeigthScaleIcon sx={{ fontSize: "22px", mr: 1 }} />}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1, p: "16px",
                            borderRadius: "12px",
                            backgroundColor: "#F2F2F2"
                        }}>
                            {
                                patient.anthropometrics?.map(anthropometria => { 
                                    return <CardInfo title={`Antropometria - ${getFormattedDate(anthropometria.createdDate)}`} icon={<AppFileMedicalIcon sx={{ fontSize: "18px", mr: 1 }} />} /> 
                                })
                            }
                        </Box>
                    </AppAcordion>
                }

                <AppAcordion
                    defaultExpanded={false}
                    title="Recordatório Alimentar"
                    sx={{ mt: 3 }}
                    titleIcon={<AppBookMedicalIcon sx={{ fontSize: "22px", mr: 1 }} />}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1, p: "16px",
                        borderRadius: "12px",
                        backgroundColor: "#F2F2F2"
                    }}>

                        <CardInfo title="Plano Alimentar 1 - 01-01-2021" icon={<AppBookMedicalIcon sx={{ fontSize: "18px", mr: 1 }} />} />
                        <CardInfo title="Plano Alimentar 2 - 01-02-2021" icon={<AppBookMedicalIcon sx={{ fontSize: "18px", mr: 1 }} />} />
                        <CardInfo title="Plano Alimentar 3 - 01-02-2021" icon={<AppBookMedicalIcon sx={{ fontSize: "18px", mr: 1 }} />} />
                        <CardInfo title="Plano Alimentar 4 - 01-02-2021" icon={<AppBookMedicalIcon sx={{ fontSize: "18px", mr: 1 }} />} />
                    </Box>
                </AppAcordion>
                <AppModal isOpen={modalEditPatientIsOpen} handleClose={() => setModalEditPatientIsOpen(false)} title="Editar Paciente">
                    <PatientForm initialData={patient} handleUpdatedPatient={handleUpdatedPatient} />
                </AppModal>
            </AppCard>
        </AppMainLayout>
    );
};

export default PatientDetailPage;





