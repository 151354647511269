import axios, { AxiosInstance } from 'axios';
import { LoginResponseData } from '../hooks/core/AuthProvider';
import { getAuthData as getAuthData, clearUserAndToken, updateUserAndToken } from '../storage/storageAuthToken';
import { ROUTES } from '../constants/routes';

type SignOut = () => void

type APIInstanceProps = AxiosInstance & {
  registerInterceptTokenManager: (signOut: SignOut) => () => void
}

class ErrorValidation extends Error {};

let retryTotal = 0;

const api = axios.create({
  baseURL: `https://appnutrifit.com.br:5000/api/v1`
}) as APIInstanceProps;

const getTokenWithRefreshToken = async (refreshToken: string, email: string): Promise<LoginResponseData | undefined> => {
  try {
    const response = await api.post('/users/refresh-token', {
      refreshToken,
      email
    });

    return response.data as LoginResponseData;

  } catch(error: any) {
    resetConfiguration();
    clearUserAndToken();

    window.location.href = ROUTES.LOGIN;
  }
}

const checkRetryTotal = () => {
  if (retryTotal >= 4) {
    throw new ErrorValidation("Total retries limit");
  }
}

const checkRefreshToken = () => {
  var { refreshToken } = getAuthData();
  
  if (!refreshToken) {
    throw new ErrorValidation("Refresh token invalid");
  }
}

const resetConfiguration = () => {
  retryTotal = 0;
}

const isValidUnauthorizedResponse = (requestError: any): boolean => {
  return requestError.response && 
    requestError.response.status === 401 &&
    !requestError.request.responseURL.includes("login")
}


api.interceptors.response.use(
  function(response) { 
    return response 
  },
  function(error) {
    return new Promise(async (resolve, reject) => {
      try {
        if (isValidUnauthorizedResponse(error)) {
          
          checkRetryTotal();
          checkRefreshToken();

          var { refreshToken, user } = getAuthData();

          const originalRequestConfig = { ...error.config };

          retryTotal++;

          const token = await getTokenWithRefreshToken(refreshToken!, user.email) as LoginResponseData;
          updateUserAndToken(token);

          return resolve(api(originalRequestConfig));      
        }
        
        return reject(error);

      } catch(error: any) {

        if(error instanceof ErrorValidation) {
          return reject(error);
        }

        clearUserAndToken();
        reject(error);
      } finally {
        resetConfiguration();
      }
    })

  });

api.interceptors.request.use(function (config) {
  const { token } = getAuthData();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});


export default api;