import { getFormattedNumber, round } from "../../../../../../utils/numberUtils";
import { SkinfoldProtocolType } from "../../enums/SkinfoldProtocolType";
import { skinfoldProtocolFields } from "../../initialDataForm/bodyCompositionInitialData";
import { BodyComposition } from "../../types/BodyComposition/BodyComposition";
import { BodyCompositionItemResult } from "../../types/BodyComposition/BodyCompositionItemResult";
import { BodyCompositionResults } from "../../types/BodyComposition/BodyCompositionResults";
import AnthropometricProtocolService from "../AnthropometricProtocolService";
import { AnthropometricCaculateType, IAnthropometricProtocolService } from "../types";

export default abstract class SkinfoldProtocolServiceBase extends AnthropometricProtocolService implements IAnthropometricProtocolService {    
    protected data: BodyComposition;
    protected height: number;
    protected age: number;
    protected sumOfSkinfold: number = 0;

    protected isValid: boolean = false;

    protected bodyCompositionResult: BodyCompositionResults;

    public constructor(anthropometricCalculate: AnthropometricCaculateType) {
        super(anthropometricCalculate)
        const { data, weight, height, age } = anthropometricCalculate;
        
        this.data = data;
        this.weight = weight;
        this.height = height;
        this.age = age;

        this.bodyCompositionResult = {} as BodyCompositionResults;
    }

    public abstract calculate(): BodyComposition;

    public abstract isAllMeasures() : boolean;

    public isSkinfoldInclude(skinfoldProtocol: SkinfoldProtocolType, skinfoldProtocols: SkinfoldProtocolType[]) : boolean {
        return skinfoldProtocols.includes(Number(skinfoldProtocol));
    }

    protected isAllMeasuresWithType(skinfoldProtocolType: SkinfoldProtocolType): boolean {
        const { skinfoldProtocol } = this.data;

        if(skinfoldProtocol === null) return false;
        if(skinfoldProtocol === undefined) return false;

        let isAllMeasures = true;
        Object.keys(skinfoldProtocolFields).forEach((key) => {
            const item = skinfoldProtocolFields[key];
            const value = Number(skinfoldProtocol[item.name]);

            const protocol = Number(skinfoldProtocolType);
            
            if (item.isUsed(protocol) && value <= 0 ) {
                isAllMeasures = false;
            };
        });

        return isAllMeasures;
    }

    public getSumOfSkinfoldBodyCompositionResult(): BodyCompositionItemResult {
        let sumOfSkinfold = this.getSumOfSkinFolds();
        return {            
            description: "Soma de dobras",
            massRecomendation: {
                recomendation: "-",
                description: "-",
            },
            value: sumOfSkinfold,
            valueFormatted: getFormattedNumber(sumOfSkinfold)
        } as BodyCompositionItemResult;
    }

    protected getDefaultBodyComposition() {
        const sumOfSkinfold = this.getSumOfSkinFolds();
        const bodyComposition = this.anthropometricCalculate.data;

        let bodyCompositionTableResult = [];

        if(sumOfSkinfold > 0) {
            bodyCompositionTableResult.push(this.getSumOfSkinfoldBodyCompositionResult())
        }

        if(this.isWeigthAndHeight()) {
            bodyCompositionTableResult.push(this.bmiBodyResult);
        }

        return {
            ...bodyComposition,
            bodyCompositionResult:  {
                ...bodyComposition.bodyCompositionResult,
                sumOfSkinFolds: this.sumOfSkinfold,
                results: bodyCompositionTableResult
            },
            bmi: this.bmiBodyResult?.value
        } as BodyComposition;
    }

    protected getBodyComposition(bodyDensity: number) : BodyComposition {  
        const fatMassPercentage = this.getFatMassPercent(bodyDensity)
        let bodyCompositionResult = this.getBodyCompositionResults(fatMassPercentage);

        bodyCompositionResult = { 
            ...bodyCompositionResult, 
            bodyDensity: round(bodyDensity),
            sumOfSkinFolds: this.getSumOfSkinFolds()            
        };

        const { leanMassPercentage, leanMass, fatMass } = bodyCompositionResult;

        let bodyCompositionTableResult = [
            ...this.getLeanMassBodyCompositionResult(leanMassPercentage, leanMass), 
            ...this.getFatMassBodyCompositionResult(fatMassPercentage, fatMass),
            this.getBodyDesentyBodyCompositionResult(bodyDensity),
            this.getSumOfSkinfoldBodyCompositionResult()        
        ] as BodyCompositionItemResult[];

        if(this.isWeigthAndHeight()) {
            bodyCompositionTableResult.push(this.bmiBodyResult);
        }
        
        return {
            ...this.anthropometricCalculate.data,
            bmi: this.bmiBodyResult?.value,
            bodyCompositionResult: {
                ...bodyCompositionResult,
                results: bodyCompositionTableResult,
            }
        } as BodyComposition;
    }

    protected getSumOfSkinFolds(): number {
        const { skinfoldProtocol } = this.data;
        if(skinfoldProtocol === undefined) return 0;

        let sum = 0;
        Object.keys(skinfoldProtocolFields).forEach((key) => {
            const item = skinfoldProtocolFields[key];
            const protocol = Number(skinfoldProtocol.protocol);
            const value = skinfoldProtocol[item.name]; 

            if (item.isUsed(protocol) && value) 
                sum += value;
        });

        return sum;
    };

    protected getFatMassPercent(bodyDensity: number): number {
        if (bodyDensity <= 0) {
            throw new Error("A densidade corporal deve ser um valor positivo.");
        }

        return round(((4.95 / bodyDensity) - 4.50) * 100);
    }

    private getBodyDesentyBodyCompositionResult(bodyDensity: number) : BodyCompositionItemResult {
        return  {
            description: "Densidade Corporal",
            massRecomendation: {
                recomendation: "-",
                description: "-"                
            },
            value: bodyDensity,
            valueFormatted: getFormattedNumber(bodyDensity)
        } as BodyCompositionItemResult;
    }

}