import React from 'react';

import { Box, InputLabel, SxProps, Theme } from '@mui/material';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import AppInputError from './components/InputErro';
import { LabelType } from './enums/LabelType';
import AppTooltip from './AppTooltip';

interface AppDatePickerProps extends DesktopDatePickerProps<Moment, false> {
  fullWidth?: boolean;
  errorMessage?: string;
  labelType?: LabelType;
  boxSx?: SxProps<Theme>;
  tooltip?: string | JSX.Element;
  tooltipPosition?: "top" | "right" | "bottom" | "left" | undefined;
}

const AppDatePicker: React.FC<AppDatePickerProps> = (props) => {
  const { boxSx,label, tooltip, tooltipPosition } = props;
  let labelType = props.labelType;

  if(labelType === null || labelType === undefined)
  {
    labelType = LabelType.FLOATING
  }

  return (
    <Box sx={{ ...boxSx }}>
      {labelType === LabelType.INLINE &&
        <Box sx={{ display: "flex" }}>
          <InputLabel sx={{ color: "text.secondary", fontWeight: "bold" }}>
            {label}
          </InputLabel>
          <AppTooltip
            position={tooltipPosition}
          >{tooltip}
          </AppTooltip>
        </Box>
      }
      <DesktopDatePicker
        {...props}
        format='DD/MM/YYYY'
        slotProps={{
          textField: {
            label: labelType === LabelType.FLOATING ? label : "",
            sx: {
              padding: 0,
              borderRadius: 18,
              borderWidth: '0px',
              border: `0px solid ${props.errorMessage ? 'red!important' : 'grey'}`,
              width: props.fullWidth ? '100%' : 'auto',
            }
          }
        }}
      />
      <AppInputError error={props.errorMessage} />
    </Box>
  );
}


export default AppDatePicker;