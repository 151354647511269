import { useEffect, useMemo, useState } from 'react';
import api from "../../../../services/api";
import { Save as SaveIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import AppMainLayout from '../../../../components/layout/AppMainLayout';
import AppCard from '../../../../components/layout/AppCard';
import AppMainHeader from '../../../../components/layout/AppMainHeader';
import AppInterationsPageHeader from '../components/AppInterationsPageHeader';
import { ROUTES } from '../../../../constants/routes';
import { Chip, Grid } from '@mui/material';
import AppTextField from '../../../../components/forms/AppTextField';
import LifeHabitSection from './LifeHabitSection';
import PathologiesSection from './PathologiesSection';
import ClinicEvaluationSection from './ClinicEvaluationSection';
import MetabolicScreeningSection from './MetabolicScreeningSection';
import { useAnamnesisStore } from '../../Stores/AnamnesisStore/AnamnesisStore';
import { usePatientStore } from '../../Stores/PatientStore/PatientStore';
import { useAnamnesisRefDataStore } from '../../Stores/AnamnesisStore/AnamnesisRefDataStore';
import { AnamnesisData, ApiMetabolicScreeningItem } from '../../Stores/AnamnesisStore/types.d';
import moment from 'moment';
import {useAppToast} from "../../../../hooks/core/AppToastContextProvider";
import {AxiosResponse} from "axios";

const AnamnesisPage = () => {

    const params = useParams();
    const navigate = useNavigate();
    const { patientId, anamnesisId } = params;
    const [mode] = useState<"edit" | "create">(anamnesisId !== undefined ? "edit" : "create");
    const { addToast } = useAppToast();
    const { patient } = usePatientStore();

    const setData = useAnamnesisStore(store => store.setData);
    const fillAllData = useAnamnesisStore(store => store.fillAllData);
    const data = useAnamnesisStore(store => store.data);
    const setRefData = useAnamnesisRefDataStore(store => store.setData);

    

    useEffect(()=>{
        const fetchData = async ()=> {
            const response = await api.get("refdata/anamnesis-metabolic-screenings");
            const itemsRef: ApiMetabolicScreeningItem[] = response.data;
            setRefData("metabolicScreeningItemsRef", itemsRef);

            if(anamnesisId) {
                const response = await api.get(`anamnesis/${anamnesisId}`);
                // console.log(response.data);
                const anamnesisData = response.data as AnamnesisData;
                anamnesisData.creationDate = new Date(anamnesisData.creationDate);
                anamnesisData.clinicEvaluation.lastMenstruationDate = moment(anamnesisData?.clinicEvaluation?.lastMenstruationDate);

                fillAllData(anamnesisData);
            }
        }

        fetchData();
        
    },[setRefData, anamnesisId]);


    const Sections = useMemo(() => {
        return (
            <>
                <Grid container spacing={0} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <LifeHabitSection complete={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <PathologiesSection complete={false} />
                    </Grid>
                    <Grid item xs={12}>
                        <ClinicEvaluationSection complete={false} />
                    </Grid>
                    <Grid item xs={12}>
                        <MetabolicScreeningSection complete={false} />
                    </Grid>
                </Grid>
            </>
        );
    }, []);


    if (patientId !== patient.id) {
        navigate(ROUTES.MY_PATIENTS);
        return (<></>);
    }

    

    return (
        <AppMainLayout>
            <AppMainHeader hasBreadCrumbs={true} />
            <AppCard>
                <AppInterationsPageHeader
                    title={mode === "create" ? "Nova Anamnese" : "Edição da Anamnese"}
                    subtitle={patient.name}
                    action={
                        {
                            label: "Salvar",
                            icon: <SaveIcon/>,
                            onClick: async () => {

                                try {
                                    setData("patientId", patientId);

                                    data.patientId = patientId!;
                                    let response: AxiosResponse<any, any>;

                                    if (data.id === null) {
                                        response = await api.post(`anamnesis`, data);
                                    } else {
                                        response = await api.put(`anamnesis/${data.id}`, data);
                                    }

                                    // On Create
                                    if (response.status === 201) {
                                        addToast({
                                            title: "Sucesso",
                                            description: "Anamnese criada com sucesso!",
                                            type: "success"
                                        });

                                        setTimeout(() => {
                                            navigate(`/meus-pacientes/${patientId}/anamnese/${response.data}`);
                                            window.location.reload();
                                        }, 1000);

                                    }
                                    // On Update
                                    if (response.status === 200) {

                                        addToast({
                                            title: "Sucesso",
                                            description: "Anamnese atualizada com sucesso!",
                                            type: "success"
                                        });

                                    }
                                }catch(e) {
                                    addToast({
                                        title: "Erro",
                                        description: "Não foi possível salvar a anamnese. Tente novamente mais tarde ou contate o nosso suporte",
                                        type: "error"
                                    })
                                }
                            }
                        }
                    }
                />

               
                <Grid container spacing={2} sx={{ mb: 4 }}>
                    <Grid item xs={12}>
                    {
                    mode === "edit" &&
                        <Chip
                            label={"Data de criação: " + data.creationDate.toLocaleDateString()}
                        />
                    }
                    </Grid>

                </Grid>
                

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        <AppTextField
                            fullWidth
                            multiline
                            rows={4}
                            name="notes"
                            type="text"
                            label="Caso clínico (histórico, objetivo do paciente etc.)"
                            value={data.notes}
                            onChange={(e) => setData("notes", e.target.value)}
                        />
                    </Grid>
                </Grid>

                {Sections}

            </AppCard>
        </AppMainLayout>
    );
};

export default AnamnesisPage;