import React, { ChangeEvent } from "react";
import { Box, debounce, Divider, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import AppMainLayout from "../../components/layout/AppMainLayout";
import AppModal from "../../components/layout/AppModal";
import AppMainHeader from "../../components/layout/AppMainHeader";
import AppCard from "../../components/layout/AppCard";
import AppTable, { AppTableColumn, AppTableRowData } from "../../components/layout/AppTable/AppTable";
import AppButton from "../../components/forms/AppButton";
import NewClinicForm from "./components/NewClinicForm";
import { useAuth } from "../../hooks/core/AuthProvider";
import { useAppToast } from '../../hooks/core/AppToastContextProvider';
import { useDialog } from "../../hooks/core/AppDialog/AppDialogProvider";

import { Clinic } from "../../domain/interfaces/Clinic";
import api from "../../services/api";
import AppTableCrudActions, { AppTableCrudActionsEnum } from "../../components/layout/AppTable/AppTableCrudActions";
import AppLoading from "../../components/utils/AppLoading";
import AppSearchIcon from "../../components/icons/AppSearchIcon";
import AppTextField from "../../components/forms/AppTextField";

const MyClinicsPage: React.FC = () => {
  const [modalNewClinicIsOpen, setModalNewClinicIsOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isFiltered, setIsFiltered] = React.useState(true);
  const [clinics, setClinics] = React.useState([] as AppTableRowData[]);
  const { user } = useAuth();
  const { addToast } = useAppToast();
  const  dialog  = useDialog();


  const columns: AppTableColumn[] = [
    { prop: 'name', label: 'Nome', maxWidth: "300px" },
    { prop: 'description', label: 'Descrição' },
    { prop: 'actions', label: 'Ações', align: 'left', maxWidth: "150px"}
  ];

  const getMyClinics = async ()=> {
    const response = await api.get(`/nutricionists/${user?.id}/clinics`);
    if(response.status === 200){
      const clinicsList = response.data as Clinic[];
      const clinicsData = clinicsList.map(clinic => {
        return {
          name: { value: clinic.name },
          description: { value: clinic.description },
          actions: { value: clinic.name, render: (value) => {
            return (
              <AppTableCrudActions 
                actions={[
                  { action: AppTableCrudActionsEnum.VIEW, title: "Visualizar Consultório" },
                  { action: AppTableCrudActionsEnum.EDIT, title: "Editar Consultório" },
                  { action: AppTableCrudActionsEnum.DELETE, title: "Apagar Consultório", onClick: handleDeleteClinic }
                ]} 
                data={clinic} 
                basePath={`/meus-consultorios`} 
              />
            );
          }}
        } as AppTableRowData;
      });

      setClinics(clinicsData);
    }
  }

  const onSubmit = async (clinic: Clinic) => {
    const nutricionistId = user?.id;
    try {
      setIsProcessing(true);
      const response = await api.post(`/nutricionists/${nutricionistId}/clinics`, clinic);
      
      if(response.status === 201){
        await getMyClinics();
        setModalNewClinicIsOpen(false);
        addToast({
          title: "Consultório Adicionado",
          description: "Consultório adicionado com sucesso!",
          type: "success"
        });
      }

    }catch(errors){
      addToast({
        title: "Erro ao adicionar consultório",
        description: "Ocorreu um erro ao adicionar o consultório, tente novamente!",
        type: "error"
      });
    }
    setIsProcessing(false);
  }

  const handleDeleteClinic = async (clinic: Clinic) => {

    const isConfirmed = await dialog.confirm({
      title: "Apagar consultório",
      message: `Você tem certeza que deseja apagar o consultório <b>${clinic.name}</b>?`
    });

    if(isConfirmed){
      setIsProcessing(true);
      
      const response = await api.delete(`/nutricionists/${user?.id}/clinics/${clinic.id}`);
      
      setIsProcessing(false);
      if(response.status === 200){
        await getMyClinics();
        addToast({
          title: "Consultório Apagado",
          description: "Consultório apagado com sucesso!",
          type: "success"
        });
        return;
      }

      addToast({
        title: "Erro ao apagar consultório",
        description: "Ocorreu um erro ao apagar o consultório, tente novamente!",
        type: "error"
      });
    }
  }

  const handleSearchClinics = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsProcessing(true);
    const value = event.target.value;
    // const response = await PatientService.getPatientsByEmailOrName({
    //   email: value,
    //   name: value,
    //   nutricionistId: user.id
    // } as GetPatientsByEmailOrName);

    // handleResponse(response);
    setIsProcessing(false);
  }

  // React.useEffect(() => {
  //   // getMyPatients();
  // }, []);

  // React.useEffect(() => {
  //   // getMyPatients();
  // }, [pagination?.rowsPerPage, pagination?.page]);

  React.useEffect(() => {
    getMyClinics();
  },[user?.id]);

  return (
    <AppMainLayout>
      <AppMainHeader hasBreadCrumbs={false} />
      <AppLoading  isLoading={isProcessing} />

      <AppCard>
        <Typography variant="h5" component="h2" sx={{ fontWeight: "bold" }}>
          Meus Consultórios
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", mb: 2 }}>
          
          <AppTextField
            sx={{
              backgroundColor: "#f5f5f5",
              width: "400px",
              "&:hover": {
                outline: "none",
                borderColor: "red",
              },
            }}
            name="search"
            type="text"
            autoFocus={false}
            startAdornment={<AppSearchIcon sx={{ mr: 0 }} />}
            placeholder="Pesquisar Consultórios"
            onChange={debounce((e) => handleSearchClinics(e), 1000)}
            onBlur={debounce((e) => handleSearchClinics(e), 1000)}
            fullWidth={false}
          />
          
          <AppButton
            variant="contained"
            startIcon={<Add />}
            label="Adicionar Consultório"
            fullWidth={false}
            onClick={() => setModalNewClinicIsOpen(true)}
          />
        </Box>
        <AppTable rows={clinics} columns={columns} />
        
      </AppCard>

      <AppModal isOpen={modalNewClinicIsOpen} handleClose={() => setModalNewClinicIsOpen(false)} title="Novo Consultório">
        <NewClinicForm isProcessing={isProcessing} onSubmit={onSubmit} />
      </AppModal>
    </AppMainLayout>
  );
};

export default MyClinicsPage;
