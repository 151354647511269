import { Gender } from "../../../../../../domain/interfaces/Patient";
import AnthropometricService from "../../../../../../services/anthropometric.services";
import { Anthropometric } from "../../types/Anthropometric";
import { BodyComposition } from "../../types/BodyComposition/BodyComposition";
import { BodyCompositionItemResult } from "../../types/BodyComposition/BodyCompositionItemResult";
import { AnthropometricChildCaculateType } from "../types";

export type WeltmanProtocolCalculateType = {
    weight: number;
    height: number;
    data: BodyComposition;
    birthDate: string;
    gender: Gender;
}

export default class ChildAnthropometricService {
    
    private anthropometricCalculate: AnthropometricChildCaculateType;

    constructor(anthropometricCalculate: AnthropometricChildCaculateType) {
        this.anthropometricCalculate = anthropometricCalculate;
    }
    
    public async calculate(): Promise<Anthropometric> {
        if(!this.isAllMeasures()){
            return {
                bodyComposition: {
                    bodyCompositionResult: {
                        results: [] as BodyCompositionItemResult[]

                    }
                }
            } as Anthropometric;
        }

        return await this.getAnthropometricResults();
    }

    public isAllMeasures(): boolean {
        const { weight, height } = this.anthropometricCalculate;
        return weight > 0 && height > 0;
    }
    
    private async getAnthropometricResults() : Promise<Anthropometric> {  
        const { height, weight } = this.anthropometricCalculate;
        const { patientId } = this.anthropometricCalculate;
        
        return await AnthropometricService.calculateChildAnthropometricResult(patientId, height, weight);
    }
}