import { AnthropometricAgeChart } from "../domain/interfaces/PatientIterations/AnthropometricAgeChart";
import { Anthropometric } from "../pages/MyPatients/Interations/Anthropometric/types/Anthropometric";
import api from "./api";

export default class AnthropometricService {
  
  public static getAgeWeightChartAnthropometrics(patientId: string): Promise<AnthropometricAgeChart[]> {
    return new Promise((resolve, reject) => {
      api.get(`/anthropometrics/${patientId}/age-weight-chart`)
        .then((response) => {
          if(response.status === 204) return [];
          
          const anthropometricsAgeWeightChart = response.data.map((Anthropometric: AnthropometricAgeChart) => Anthropometric) as AnthropometricAgeChart[];
          resolve(anthropometricsAgeWeightChart);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getAgeHeightChartAnthropometrics(patientId: string): Promise<AnthropometricAgeChart[]> {
    return new Promise((resolve, reject) => {
      api.get(`/anthropometrics/${patientId}/age-height-chart`)
        .then((response) => {
          if(response.status === 204) return [];
          
          const anthropometricsAgeHeightChart = response.data.map((Anthropometric: AnthropometricAgeChart) => Anthropometric) as AnthropometricAgeChart[];
          resolve(anthropometricsAgeHeightChart);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static getAgeBMIChartAnthropometrics(patientId: string): Promise<AnthropometricAgeChart[]> {
    return new Promise((resolve, reject) => {
      api.get(`/anthropometrics/${patientId}/age-bmi-chart`)
        .then((response) => {
          if(response.status === 204) return [];
          
          const anthropometricsAgeBMIChart = response.data.map((Anthropometric: AnthropometricAgeChart) => Anthropometric) as AnthropometricAgeChart[];
          resolve(anthropometricsAgeBMIChart);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public static calculateChildAnthropometricResult(patientId: string, height: number, weight: number): Promise<Anthropometric> {
    return new Promise((resolve, reject) => {
      const url = `/anthropometrics/${patientId}/calculate-child-result?height=${height}&weight=${weight}`;
      api.get(url)
        .then((response) => {
          if(response.status === 204) return null;    
          resolve(response.data as Anthropometric);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}



