import { FC, useCallback } from "react";
import AppCheckAccordion from "../../../../components/layout/AppCheckAccordion";
import { Grid } from "@mui/material";
import AppFormTitleDivider from "../../components/AppFormTitleDivider";
import AppArmIcon from "../../../../components/icons/AppArmIcon";
import AppHumanTorsonIcon from "../../../../components/icons/AppHumanTorsonIcon";
import AppRulerIcon from "../../../../components/icons/AppRulerIcon";
import AppLegIcon from "../../../../components/icons/AppLegIcon";
import { useAnthropometricStore } from "./store/AnthropometricStore";
import { SectionProps } from "./interfaces/SectionProps";
import AppMaskedNumberField from "../../../../components/forms/AppMaskedNumberField";
import { LabelType } from "../../../../components/forms/enums/LabelType";
import { Circuferences } from "./types/Circuferences/Circuferences";

const CircumferencesSection: FC<SectionProps> = ({ complete, updateResults }) => {

    const store = useAnthropometricStore(state => state.data.circuferences);
    const data = store;

    const setCircuferencesData = useCallback((propName: keyof Circuferences, fieldName: string, value: any) => {
        const valueUpdated = { ...data, [propName]: { ...data[propName], [fieldName]: Number(value) } };
        updateResults(valueUpdated);
    }, [data, updateResults]);

    return (
        <AppCheckAccordion
            title="Circuferências"
            complete={complete}>
            <AppFormTitleDivider text="Membros superiores" icon={AppArmIcon} iconColor={"#954ff7"} />
            <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="relaxedArmRigth"
                        label="Braço direito relaxado"
                        value={data.upperLimbesMeasurements.relaxedArmRigth}
                        onValueChange={(value) => setCircuferencesData("upperLimbesMeasurements", "relaxedArmRigth", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="relaxedArmLeft"
                        label="Braço esquerdo relaxado"
                        value={data.upperLimbesMeasurements.relaxedArmLeft}
                        onValueChange={(value) => setCircuferencesData("upperLimbesMeasurements", "relaxedArmLeft", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="contractedArmRigth"
                        label="Braço direito contraído"
                        value={data.upperLimbesMeasurements.contractedArmRigth}
                        onValueChange={(value) => setCircuferencesData("upperLimbesMeasurements", "contractedArmRigth", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="contractedArmLeft"
                        label="Braço esquerdo contraído"
                        value={data.upperLimbesMeasurements.contractedArmLeft}
                        onValueChange={(value) => setCircuferencesData("upperLimbesMeasurements", "contractedArmLeft", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="forearmRigth"
                        label="Antebraço Direito"
                        value={data.upperLimbesMeasurements.forearmRigth}
                        onValueChange={(value) => setCircuferencesData("upperLimbesMeasurements", "forearmRigth", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="forearmLeft"
                        label="Antebraço Esquerdo"
                        value={data.upperLimbesMeasurements.forearmLeft}
                        onValueChange={(value) => setCircuferencesData("upperLimbesMeasurements", "forearmLeft", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="fistRigth"
                        label="Punho Direito"
                        value={data.upperLimbesMeasurements.fistRigth}
                        onValueChange={(value) => setCircuferencesData("upperLimbesMeasurements", "fistRigth", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="fistLeft"
                        label="Punho Esquerdo"
                        value={data.upperLimbesMeasurements.fistLeft}
                        onValueChange={(value) => setCircuferencesData("upperLimbesMeasurements", "fistLeft", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
            </Grid>

            <AppFormTitleDivider text="Tronco" icon={AppHumanTorsonIcon} iconColor={"#954ff7"} />

            <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="neck"
                        label="Pescoço"
                        value={data.steamLimbesMeasurements.neck}
                        onValueChange={(value) => setCircuferencesData("steamLimbesMeasurements", "neck", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="shoulder"
                        label="Ombro"
                        value={data.steamLimbesMeasurements.shoulder}
                        onValueChange={(value) => setCircuferencesData("steamLimbesMeasurements", "shoulder", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="breastplate"
                        label="Peitoral"
                        value={data.steamLimbesMeasurements.breastplate}
                        onValueChange={(value) => setCircuferencesData("steamLimbesMeasurements", "breastplate", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="waist"
                        label="Cintura"
                        value={data.steamLimbesMeasurements.waist}
                        onValueChange={(value) => setCircuferencesData("steamLimbesMeasurements", "waist", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="abdomen"
                        label="Abdômen"
                        value={data.steamLimbesMeasurements.abdomen}
                        onValueChange={(value) => setCircuferencesData("steamLimbesMeasurements", "abdomen", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="hip"
                        label="Quadril"
                        value={data.steamLimbesMeasurements.hip}
                        onValueChange={(value) => setCircuferencesData("steamLimbesMeasurements", "hip", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
            </Grid>

            <AppFormTitleDivider text="Membros Inferiores" icon={AppLegIcon} />

            <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="calfRigth"
                        label="Panturrilha direita"
                        value={data.lowerLimberMeasurements.calfRigth}
                        onValueChange={(value) => setCircuferencesData("lowerLimberMeasurements", "calfRigth", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="calfLeft"
                        label="Panturrilha esquerda"
                        value={data.lowerLimberMeasurements.calfLeft}
                        onValueChange={(value) => setCircuferencesData("lowerLimberMeasurements", "calfLeft", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="thighRigth"
                        label="Coxa direita"
                        value={data.lowerLimberMeasurements.thighRigth}
                        onValueChange={(value) => setCircuferencesData("lowerLimberMeasurements", "thighRigth", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="thighLeft"
                        label="Coxa esquerda"
                        value={data.lowerLimberMeasurements.thighLeft}
                        onValueChange={(value) => setCircuferencesData("lowerLimberMeasurements", "thighLeft", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="rightProximalThigh"
                        label="Coxa proximal direita"
                        value={data.lowerLimberMeasurements.rightProximalThigh}
                        onValueChange={(value) => setCircuferencesData("lowerLimberMeasurements", "rightProximalThigh", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="leftProximalThigh"
                        label="Coxa proximal esquerda"
                        value={data.lowerLimberMeasurements.leftProximalThigh}
                        onValueChange={(value) => setCircuferencesData("lowerLimberMeasurements", "leftProximalThigh", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
            </Grid>

            <AppFormTitleDivider text="Diâmetros ósseos" icon={AppRulerIcon} />

            <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
                <Grid item md={4} xs={12} >
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="femur"
                        label="Fêmur"
                        value={data.boneDiameters.femur}
                        onValueChange={(value) => setCircuferencesData("boneDiameters", "femur", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
                <Grid item md={4} xs={12} >
                    <AppMaskedNumberField
                        labelType={LabelType.INLINE}
                        fullWidth
                        name="fistArm"
                        label="Punho"
                        value={data.boneDiameters.fistArm}
                        onValueChange={(value) => setCircuferencesData("boneDiameters", "fistArm", value)}
                        placeholder="0,00 cm"
                        suffix=" cm"
                    />
                </Grid>
            </Grid>
        </AppCheckAccordion>
    );

}

export default CircumferencesSection;