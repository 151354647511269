import { Box,  FormLabel, Typography } from "@mui/material";
import { FC, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PanoramaIcon from '@mui/icons-material/Panorama';
import { useSelector } from "react-redux";
import { LayoutState } from "../../state/reducers/layout.reducer";
import { SxProps, useTheme } from "@mui/material/styles";
import AppTooltip from "./AppTooltip";

interface AppRichSelectProps {
  label?: string;
  options: AppRichSelectOption[];
  value?: any | null;
  onSelected: (value: any) => void;
  tooltip?: string | JSX.Element;
  tooltipPosition?: "top" | "right" | "bottom" | "left" | undefined;

}

export interface AppRichSelectOption {
  id: any;
  title: string;
  description: string;
  srcImg?: string;
}

type CardProps = {
  index: number;
  option: AppRichSelectOption;
}


const AppRichSelect:FC<AppRichSelectProps> = ({ label, value, options, onSelected, tooltip, tooltipPosition }) => {

  const layout: LayoutState = useSelector((state: any) => state.layout);
  const theme = useTheme();

  const localIsMobile = layout.windowSize.width <= 736;
  
  const loadedIndex: number | null = value ? options.findIndex(option => option.id === value) : null;
  
  const selectedOption = loadedIndex !== null ? options[loadedIndex] : null;

  // const [selectedOption, setSelectedOption] = useState(loadedOption);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(loadedIndex);
  const [isSelecting, setIsSelecting] = useState(false);


  const handleToggleSelect = (option: AppRichSelectOption, key: number) => {
    // setSelectedOption(option);
    setSelectedIndex(key);
    setIsSelecting(false);
    onSelected(option.id);
  };

  const containerStyle = {
    border: '1px solid #ccc', 
    borderRadius: "8px", 
    cursor:"pointer",
    p: 1,
    m:0,
    transition: "all 0.3s",
  }

  const Card:FC<CardProps> = ({index, option}) => {
    
    const selectedStyle = (index === selectedIndex) ? {border: `2px solid ${theme?.palette?.secondary?.main}`} : {border: `2px solid transparent`};
    const cardSytles: SxProps = { 
        width:"10%",
        minWidth: localIsMobile ? "250px" : "130px",
        boxShadow: "0 0 5px #ccc",
        display:"flex", 
        p:1,
        gap: 1,
        flexDirection: localIsMobile ? "row" : "column",
        ...selectedStyle,
        "&:hover": {
          boxShadow: "0 0 10px #ccc",
          transform: "scale(1.05)",
          border: `1px solid ${theme?.palette?.secondary?.main}`,
        },
        alignItems:"center",
        borderRadius: "8px",
        transition: "all 0.1s",
        
    };

    const imageContainerStyle = {
      width:"90%", 
      maxWidth: localIsMobile ? "4rem" : "7rem",
      borderRadius:"8px", 
      height: localIsMobile ? "4rem" : "5rem", 
      backgroundColor:"#efefef", 
      display:"flex", 
      alignItems:"center", 
      justifyContent:"center"
    };

    return (
      <Box key={index} sx={cardSytles} onClick={()=>handleToggleSelect(option, index)}>
        
       {!localIsMobile &&  <Typography variant={"h6"}><strong>{option.title}</strong></Typography> }
        {option.srcImg &&  <img style={imageContainerStyle} src={option.srcImg} alt={option.title} /> }
        {!option.srcImg &&
          <Box sx={imageContainerStyle}>
            <PanoramaIcon sx={{ fontSize: localIsMobile ? "2rem" : "4rem",  color:"#ccc" }} />
          </Box>
        }
        <Box>
          {localIsMobile &&  <Typography variant={"h6"} sx={{m:0, p:0}}><strong>{option.title}</strong></Typography> }
          <Typography component={"p"} sx={{ fontSize:"0.7rem"}}>{option.description}</Typography>
        </Box>
      </Box>
    )
  }


  return (
   <Box>
      <Box sx={{display:"flex"}}>
        {label && <FormLabel sx={{mb:1, fontWeight:"bold"}}>{label}</FormLabel>}
        <AppTooltip position={tooltipPosition}>{tooltip}</AppTooltip>
      </Box>
      <Box sx={containerStyle}  >
        { selectedOption !== null && 
        <Box onClick={()=>setIsSelecting(!isSelecting)} sx={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
          <Typography><strong>{selectedOption.title}</strong> - {selectedOption.description}</Typography>
          {
            isSelecting ?  
              <KeyboardArrowUpIcon sx={{color:theme.palette.text.secondary}} /> 
              :
              <KeyboardArrowDownIcon sx={{color:theme.palette.text.secondary}} />
          }
        </Box> 
      }

        { selectedOption === null && 
          <Box onClick={()=>setIsSelecting(!isSelecting)} sx={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
            <Typography>Selecione uma opção</Typography>
            {
              isSelecting ?  
                <KeyboardArrowUpIcon sx={{color:theme.palette.text.secondary}} /> 
                :
                <KeyboardArrowDownIcon sx={{color:theme.palette.text.secondary}} />
            }
          </Box>
        }

        {isSelecting &&
          <Box sx={{display:"flex", pt:2, gap:2, flexWrap:"wrap", justifyContent:"center"}}>
            {options.map((option, index) => (
             <Card key={index} index={index} option={option} />
            ))}
          </Box>
        }
        
      </Box>
    </Box>
  );
}

export default AppRichSelect;