import {create } from 'zustand';
import { AnamnesisData, AnamneseStore } from '../AnamnesisStore/types.d';


export const useAnamnesisStore = create<AnamneseStore>((set) => ({
    
    data: {
        id: null,
        patientId: "",
        notes: "",
        creationDate: new Date(),
        lifeHabit: {
            sleepQuality: "",
            amountSleepDay: 0,
            amountSleepNight: 0,
            sleepNotes: "",
            whoBuys: "",
            whereBuys: "",
            peopleLivingTogether: "",
            timesOfBuysInMonth: "",
            oilLiterMonth: "",
            kgSaltMonth: "",
            kgSuggarMonth: "",
            buyNotes: "",
            exerciseFrequency: "",
            exerciseNotes: "",
            foodRestriction: "",
            otherFoodRestriction: "",
            foodNotes: "",
            appetite: "",
            ingestionChew: "",
            amountWaterPerDay: "",
            smoker: "",
            alcoholic: "",
            foodSuplementation: "",
            foodAllergy: "",
            foodIntolerance: "",
            foodAversion: "",
        },
        pathologiesDetail: {
            pathologiesList: [],
            medicines: "",
            exams: "",
            pathologiesFamilyHistory: "",
            notes: "",
        },
        clinicEvaluation: {
            intestinalHabitMode: "",
            evacuationFrequency: "",
            useLaxative: "",
            urineColor: "",
            intestinalHabitNotes: "",
            womanNormalMenstrualCycle: null,
            tpmClassification: "",
            lastMenstruationDate: null,
            contraceptive: "",
            hasColics: null,
            isLactating: null,
            isMenopause: null,
            stoolType: null,
            stoolColor: "",
            stoolTypeClassification: null,
            stoolColorClassification: null,
            stoolColorSeverity: "",
            stoolTypeSeverity: "",
            hairNotes: "",
            faceNotes: "",
            eyesNotes: "",
            lipsNotes: "",
            gumsNotes: "",
            nailsNotes: "",
            dentitionNotes: "",
        },
        metabolicScreenings: [],
    },
    setData: (propName: keyof AnamnesisData, data: any) => {
        set((state) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    [propName]: data
                }
            }
        });
    },
    fillAllData: (anamnesisData: AnamnesisData) => {
        set((state)=> {
            return {
                ...state,
                data: {
                    ...anamnesisData
                }
            }
        })
    }
    
}));