import {create } from 'zustand';
import { AnthropometricStore } from './types.d';
import { PatientType } from '../enums/PatientType';
import { BodyCompositionType } from '../enums/BodyCompositionType';
import { Anthropometric } from '../types/Anthropometric';

export const useAnthropometricStore = create<AnthropometricStore>((set) => ({
    data: {
        id: "",
        createdDate: new Date(),
        updatedDate: new Date(),
        height: 0,
        idealWeight: 0,
        patientType: PatientType.Adult,
        weight: 0,
        description: "",
        bodyComposition: {      
            bmi: 0,      
            bodyCompositionType: BodyCompositionType.SkinfoldProtocol,
            bodyCompositionResult: {
                bodyDensity: 0,
                fatMass: 0,
                fatMassPercentage: 0,
                leanMass: 0,
                leanMassPercentage: 0,
                sumOfSkinFolds: 0
            },
            skinfoldProtocol: {
                abdominal: 0,
                biceps: 0,
                middleAxillary: 0,
                medialCalf: 0,
                subscapularis: 0,
                suprailiaca: 0,
                thigh: 0,
                thorax: 0,
                triceps: 0,
                protocol: 3
            },
            bioimpedance: {
                bmi: 0,
                fatMassPercent: 0,
                fatMass: 0,
                leanMassPercent: 0,
                leanMass: 0,
                bodyWaterPercent: 0,
                bodyWater: 0,
                boneWeight: 0,
                residualWeight: 0,
                muscleWeight: 0,
                visceralFatPercent: 0,
                metabolicAge: 0,
                skeletalMusclesPercent: 0
            },
            weltmanProtocol: {
                abdomen1: 0,
                abdomen2: 0
            }
        },
        circuferences: {
            createdDate: new Date(),
            updatedDate: new Date(),
            id: "",
            boneDiameters: {
                femur: 0,
                fistArm: 0
            },
            lowerLimberMeasurements: {
                calfRigth: 0,
                calfLeft: 0,
                thighRigth: 0,
                thighLeft: 0,
                leftProximalThigh: 0,
                rightProximalThigh: 0
            },
            steamLimbesMeasurements: {
                neck: 0,
                shoulder: 0,
                breastplate: 0,
                waist: 0,
                abdomen: 0,
                hip: 0
            },
            upperLimbesMeasurements: {
                relaxedArmRigth: 0,
                relaxedArmLeft: 0,
                contractedArmRigth: 0,
                contractedArmLeft: 0,
                forearmRigth: 0,
                forearmLeft: 0,
                fistRigth: 0,
                fistLeft: 0
            }
        }
    },
    setData: (propName: keyof Anthropometric, data: any) => {
        set((state) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    [propName]: data
                }
            };
        });
    },

    setAnthropometricData: (data: Anthropometric) => {
        set((state) => {
            return {data};
        });
    }
}));