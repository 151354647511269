import { FC, useCallback, useEffect, useState } from "react";
import AppSelect, { AppSelectOption } from "../../../../components/forms/AppSelect";
import AppCheckAccordion from "../../../../components/layout/AppCheckAccordion";
import AppFormTitleDivider from "../../components/AppFormTitleDivider";
import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import AppMeasureIcon from "../../../../components/icons/AppMeasureIcon";
import { SkinfoldProtocolType } from "./enums/SkinfoldProtocolType";
import { useAnthropometricStore } from "./store/AnthropometricStore";
import { skinfoldProtocolFields } from "./initialDataForm/bodyCompositionInitialData";
import AppMaskedNumberField from "../../../../components/forms/AppMaskedNumberField";
import { BodyCompositionType } from "./enums/BodyCompositionType";
import { SectionProps } from "./interfaces/SectionProps";
import { LabelType } from "../../../../components/forms/enums/LabelType";
import { PatientType } from "./enums/PatientType";
import { BodyComposition } from "./types/BodyComposition/BodyComposition";

const adultSkinfoldProtocolsOptions: AppSelectOption[] = [
    { value: SkinfoldProtocolType.FourSkinfoldDurninAndWomersley, label: "4 Dobras - Durnin e Womersley (1974)" },
    { value: SkinfoldProtocolType.ThreeSkinfoldGuedes, label: "3 Dobras - Guedes (1985)" },
    { value: SkinfoldProtocolType.SevenSkinfoldJacksonAndPollockAndWard, label: "7 Dobras - Jackson; Pollock; Ward (1980)" },
    { value: SkinfoldProtocolType.ThreeSkinfoldJacksonAndPollockAndWard, label: "3 Dobras - Jackson; Pollock; Ward (1980)" },
];

const seniorCitizenSkinfoldProtocolsOptions: AppSelectOption[] = [
    { value: SkinfoldProtocolType.FourSkinfoldDurninAndWomersley, label: "4 Dobras - Durnin e Womersley (1974)" }
];

const adultProtocolsOptions: AppSelectOption[] = [
    { label: "Pregas Cutâneas", value: BodyCompositionType.SkinfoldProtocol },
    { label: "Protocolo Weltman", value: BodyCompositionType.WeltmanProtocol },
    { label: "Bioimpedância", value: BodyCompositionType.Bioimpedance }
];

const seniorCitizenProtocolsOptions: AppSelectOption[] = [
    { label: "Pregas Cutâneas", value: BodyCompositionType.SkinfoldProtocol },
    { label: "Bioimpedância", value: BodyCompositionType.Bioimpedance }
];

type BodyCompositionProtocolFieldsTypes = Exclude<keyof BodyComposition, "bodyCompositionType" | "bmi" | "sumOfSkinFolds">;

type IsUsedBodyCompositionType = {
    biceps: boolean,
    triceps: boolean,
    abdominal: boolean,
    suprailiaca: boolean,
    middleAxillary: boolean,
    subscapularis: boolean,
    thorax: boolean,
    thigh: boolean,
    medialCalf: boolean
}

const BodyCompositionSection: FC<SectionProps> = ({ complete, updateResults }) => {

    const store = useAnthropometricStore(state => state.data);
    const data = store.bodyComposition;

    const [skinfoldProtocolOptions, setSkinfoldProtocolOptions] = useState([] as AppSelectOption[]);
    const [protocolOptions, setProtocolOptions] = useState([] as AppSelectOption[]);
    
    // Estado setado conforme protocolo Inicial
    const [bodyCompositionUsedField, setBodyCompositionUsedField] = useState({
        biceps: true,
        triceps: true,
        abdominal: false,
        suprailiaca: true,
        middleAxillary: false,
        subscapularis: true,
        thorax: false,
        thigh: false,
        medialCalf: false,
    } as IsUsedBodyCompositionType);

    const updateProtocolData = useCallback((propName: BodyCompositionProtocolFieldsTypes, fieldName: string, value: any) => {
        let newData = { ...data, [propName]: { ...data[propName], [fieldName]: Number(value) } } as BodyComposition;
        updateResults(newData);
    }, [data, updateResults]);

    const updateSkinfoldProtocolType = useCallback((value: any) => {
        const protocol = Number(value);
        let newData = { ...data, skinfoldProtocol: { ...data.skinfoldProtocol, protocol: protocol } } as BodyComposition;
        const usedFields = {
            biceps: skinfoldProtocolFields.biceps.isUsed(protocol),
            triceps: skinfoldProtocolFields.triceps.isUsed(protocol),
            abdominal: skinfoldProtocolFields.abdominal.isUsed(protocol),
            suprailiaca: skinfoldProtocolFields.suprailiaca.isUsed(protocol),
            middleAxillary: skinfoldProtocolFields.middleAxillary.isUsed(protocol),
            subscapularis: skinfoldProtocolFields.subscapularis.isUsed(protocol),
            thorax: skinfoldProtocolFields.thorax.isUsed(protocol),
            thigh: skinfoldProtocolFields.thigh.isUsed(protocol),
            medialCalf: skinfoldProtocolFields.medialCalf.isUsed(protocol),
        }
        setBodyCompositionUsedField(usedFields);
        updateResults(newData);
    }, [data, updateResults, setBodyCompositionUsedField]);

    const updateTypeBodyComposition = useCallback((event: SelectChangeEvent<any>) => {
        const protocol = Number(event.target.value);
        let newData = { ...data, bodyCompositionType: protocol } as BodyComposition;
        updateResults(newData);
    }, [data, updateResults]);

    useEffect(() => {
        const  patientType = store.patientType;

        if(patientType === PatientType.Adult) 
        {
            setSkinfoldProtocolOptions(adultSkinfoldProtocolsOptions);
            setProtocolOptions(adultProtocolsOptions);
        }

        if(patientType === PatientType.SeniorCitizen)
        {
            setSkinfoldProtocolOptions(seniorCitizenSkinfoldProtocolsOptions);
            setProtocolOptions(seniorCitizenProtocolsOptions);
        }

    }, [store.patientType]);

    return (
        <AppCheckAccordion
            title="Composição Corporal"
            complete={complete}>
            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item md={8} xs={12}>
                    <AppSelect
                        fullWidth
                        name="bodyCompositionType"
                        type="text"
                        label="Tipo composição corporal"
                        onChange={updateTypeBodyComposition}
                        value={data.bodyCompositionType}
                        options={protocolOptions}
                    />
                </Grid>
            </Grid>

            <AppFormTitleDivider text="Medidas" iconColor={"#5ad05f"} icon={AppMeasureIcon} />

            {data.bodyCompositionType === BodyCompositionType.SkinfoldProtocol &&
                <>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item md={8} xs={12}>
                            <AppSelect
                                fullWidth
                                options={skinfoldProtocolOptions}
                                value={data.skinfoldProtocol.protocol}
                                onChange={(e: any) => updateSkinfoldProtocolType(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
                        <Grid item md={4} xs={12} >
                            <AppMaskedNumberField
                                isNotUsed={!bodyCompositionUsedField.biceps}
                                fullWidth
                                name="biceps"
                                label="Bícipes"
                                value={data.skinfoldProtocol.biceps}
                                onValueChange={(value) => updateProtocolData("skinfoldProtocol", "biceps", value)}
                                placeholder="0,00 mm"
                                suffix=" mm"
                                labelType={LabelType.INLINE}
                                tooltip={"A medida é realizada ao longo do eixo longitudinal do braço, na face anterior, no ponto onde o bíceps apresenta a maior circunferência aparente."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                isNotUsed={!bodyCompositionUsedField.triceps}
                                fullWidth
                                name="triceps"
                                label="Tríceps"
                                value={data.skinfoldProtocol.triceps}
                                onValueChange={(value) => updateProtocolData("skinfoldProtocol", "triceps", value)}
                                placeholder="0,00 mm"
                                suffix=" mm"
                                tooltip={"A medição é realizada na parte posterior do braço, paralela ao eixo longitudinal, no ponto que corresponde ao meio da distância entre a borda superior e lateral do acrômio e o olécrano."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                isNotUsed={!bodyCompositionUsedField.abdominal}
                                fullWidth
                                name="abdominal"
                                label="Abdominal"
                                value={data.skinfoldProtocol.abdominal}
                                onValueChange={(values) => updateProtocolData("skinfoldProtocol", "abdominal", values)}
                                placeholder="0,00 mm"
                                suffix=" mm"
                                tooltip={"A medida é realizada cerca de dois centímetros à direita da cicatriz umbilical, seguindo o eixo longitudinal."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                isNotUsed={!bodyCompositionUsedField.suprailiaca}
                                fullWidth
                                name="suprailiaca"
                                label="Suprailíaca"
                                value={data.skinfoldProtocol.suprailiaca}
                                onValueChange={(values) => updateProtocolData("skinfoldProtocol", "suprailiaca", values)}
                                placeholder="0,00 mm"
                                suffix=" mm"
                                tooltip={"A medida é feita de forma oblíqua em relação ao eixo longitudinal, no ponto médio entre o último arco costal e a crista ilíaca, ao longo da linha axilar medial. O avaliado deve deslocar o braço para trás, permitindo que a medição seja realizada."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                isNotUsed={!bodyCompositionUsedField.middleAxillary}
                                fullWidth
                                name="middleAxillary"
                                label="Axilar Média"
                                value={data.skinfoldProtocol.middleAxillary}
                                onValueChange={(values) => updateProtocolData("skinfoldProtocol", "middleAxillary", values)}
                                placeholder="0,00 mm"
                                suffix=" mm"
                                tooltip={"A localização da medida é no ponto onde a linha axilar média se encontra com uma linha imaginária transversal ao nível do apêndice xifoide do esterno. A medição é feita de forma oblíqua em relação ao eixo longitudinal, com o braço do avaliado posicionado para trás para facilitar o procedimento."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                isNotUsed={!bodyCompositionUsedField.subscapularis}
                                fullWidth
                                name="subscapularis"
                                label="Subescapular"
                                value={data.skinfoldProtocol.subscapularis}
                                onValueChange={(values) => updateProtocolData("skinfoldProtocol", "subscapularis", values)}
                                placeholder="0,00 mm"
                                suffix=" mm"
                                tooltip={"A medida é feita de forma oblíqua ao eixo longitudinal, seguindo a orientação dos arcos costais e localizada a dois centímetros abaixo do ângulo inferior da escápula."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                isNotUsed={!bodyCompositionUsedField.thorax}
                                fullWidth
                                name="thorax"
                                label="Tórax"
                                value={data.skinfoldProtocol.thorax}
                                onValueChange={(values) => updateProtocolData("skinfoldProtocol", "thorax", values)}
                                placeholder="0,00 mm"
                                suffix=" mm"
                                tooltip={"A medida é realizada de forma oblíqua em relação ao eixo longitudinal, localizada no ponto médio entre a linha axilar anterior e o mamilo para homens, e a um terço dessa distância para mulheres."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                isNotUsed={!bodyCompositionUsedField.thigh}
                                fullWidth
                                name="thigh"
                                label="Coxa"
                                value={data.skinfoldProtocol.thigh}
                                onValueChange={(values) => updateProtocolData("skinfoldProtocol", "thigh", values)}
                                placeholder="0,00 mm"
                                suffix=" mm"
                                tooltip={"A medida é realizada paralelamente ao eixo longitudinal, sobre o músculo reto femoral. Para isso, posiciona-se a um terço da distância entre o ligamento inguinal e a borda superior da patela, conforme proposto por Guedes (1985), ou na metade dessa distância, segundo Pollock & Wilmore (1993)."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                isNotUsed={!bodyCompositionUsedField.medialCalf}
                                fullWidth
                                name="medialCalf"
                                label="Panturrilha Medial"
                                value={data.skinfoldProtocol.medialCalf}
                                onValueChange={(values) => updateProtocolData("skinfoldProtocol", "medialCalf", values)}
                                placeholder="0,00 mm"
                                suffix=" mm"
                                tooltip={"Para realizar essa medição, o avaliado deve estar sentado, com o joelho flexionado em 90 graus, o tornozelo na posição anatômica e o pé sem apoio. A dobra cutânea é pinçada no ponto de maior perímetro da perna, utilizando o polegar da mão esquerda apoiado na borda medial da tíbia."}
                            />
                        </Grid>
                    </Grid>
                    <Typography sx={{ textAlign: "left", fontSize: "10px", lineHeight:"14px" }}>
                        * Somente serão utilizados para o cálculo da anthropometria os campos destacados.
                    </Typography>

                </>
            }

            {data.bodyCompositionType === BodyCompositionType.WeltmanProtocol &&
                <>
                    <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="abdomen1"
                                label="Abdômen 1"
                                value={data.weltmanProtocol.abdomen1}
                                onValueChange={(value) => updateProtocolData("weltmanProtocol", "abdomen1", value)}
                                placeholder={`0,00 cm`}
                                suffix=" cm"
                                tooltip={"Essa medida representa a circunferência abdominal e será utilizada para calcular a média dos dois valores registrados para o abdômen."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="abdomen2"
                                label="Abdômen 2"
                                value={data.weltmanProtocol.abdomen2}
                                onValueChange={(value) => updateProtocolData("weltmanProtocol", "abdomen2", value)}
                                placeholder={`0,00 cm`}
                                suffix=" cm"
                                tooltip={"Essa medida representa a circunferência abdominal e será utilizada para calcular a média dos dois valores registrados para o abdômen."}
                            />
                        </Grid>
                    </Grid>

                </>
            }

            {data.bodyCompositionType === BodyCompositionType.Bioimpedance &&
                <>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="bmi"
                                label="IMC"
                                value={data.bioimpedance.bmi}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "bmi", value)}
                                placeholder={`0,00`}
                                suffix={""}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="fatMassPercent"
                                label="% Massa Gorda"
                                value={data.bioimpedance.fatMassPercent}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "fatMassPercent", value)}
                                placeholder={`0,00%`}
                                suffix={"%"}
                                minValue={0}
                                maxValue={100}
                                tooltip={"Valor máximo permitido 100%."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="fatMass"
                                label="Massa Gorda"
                                value={data.bioimpedance.fatMass}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "fatMass", value)}
                                placeholder={`0,00Kg`}
                                suffix={"Kg"}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="leanMassPercent"
                                label="% Massa Magra"
                                value={data.bioimpedance.leanMassPercent}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "leanMassPercent", value)}
                                placeholder={`0,00%`}
                                suffix={"%"}
                                minValue={0}
                                maxValue={100}
                                tooltip={"Valor máximo permitido 100%."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="leanMass"
                                label="Massa Magra"
                                value={data.bioimpedance.leanMass}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "leanMass", value)}
                                placeholder={`0,00Kg`}
                                suffix={"Kg"}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="bodyWaterPercent"
                                label="% Agua Corporal"
                                value={data.bioimpedance.bodyWaterPercent}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "bodyWaterPercent", value)}
                                placeholder={`0,00%`}
                                suffix={"%"}
                                minValue={0}
                                maxValue={100}
                                tooltip={"Valor máximo permitido 100%."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="bodyWater"
                                label="Agua Corporal"
                                value={data.bioimpedance.bodyWater}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "bodyWater", value)}
                                placeholder={`de 0 até 100L`}
                                suffix={"L"}
                                minValue={0}
                                maxValue={100}
                                tooltip={"Valor máximo permitido 100L."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="boneWeight"
                                label="Peso Ósseo"
                                value={data.bioimpedance.boneWeight}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "boneWeight", value)}
                                placeholder={`0,00 Kg`}
                                suffix={" Kg"}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="residualWeight"
                                label="Peso Residual"
                                value={data.bioimpedance.residualWeight}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "residualWeight", value)}
                                placeholder={`0,00Kg`}
                                suffix={"Kg"}
                                minValue={0}
                                maxValue={100}
                                tooltip={"Valor máximo permitido 100Kg."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="muscleWeight"
                                label="Peso Muscular"
                                value={data.bioimpedance.muscleWeight}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "muscleWeight", value)}
                                placeholder={`0,00Kg`}
                                suffix={"Kg"}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="visceralFatPercent"
                                label="% Gordura Viceral"
                                value={data.bioimpedance.visceralFatPercent}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "visceralFatPercent", value)}
                                placeholder={`0,00%`}
                                suffix={"%"}
                                minValue={0}
                                maxValue={100}
                                tooltip={"Valor máximo permitido 100%."}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="metabolicAge"
                                label="Idade Metabólica"
                                value={data.bioimpedance.metabolicAge}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "metabolicAge", value)}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <AppMaskedNumberField
                                labelType={LabelType.INLINE}
                                fullWidth
                                name="skeletalMusclesPercent"
                                label="% Músculos esqueléticos"
                                value={data.bioimpedance.skeletalMusclesPercent}
                                onValueChange={(value) => updateProtocolData("bioimpedance", "skeletalMusclesPercent", value)}
                                placeholder={`de 0 até 100%`}
                                suffix={"%"}
                                minValue={0}
                                maxValue={100}
                                tooltip={"Valor máximo permitido 100%."}
                            />
                        </Grid>
                    </Grid>
                </>
            }
        </AppCheckAccordion>);

};

export default BodyCompositionSection;